.entry-title {
	color: $secondary-color;
}

.entry-content {
	ul {
		list-style-type: none;
		line-height:     2rem;
		padding:         0 2rem 0 0;
		
		li {
			position:    relative;
			margin-left: 2rem;
			
			&:before {
				content:          "";
				background-color: $primary-color;
				mask-image:       url("#{$img-path}/green-tick.png");
				mask-repeat:      no-repeat;
				mask-position:    center;
				vertical-align:   middle;
				min-width:        19px;
				height:           16px;
				position:         absolute;
				top:              0.25rem;
				left:             -2rem;
			}
			
			a {
				color: $dark-color;
				
				&:hover {
					color:           $primary-color;
					text-decoration: none;
				}
			}
		}
	}
}

.container-left-half {
	margin-right: auto;
	margin-left:  auto;
	width:        100%;
	@media (min-width: 576px) {
		//max-width for container at this width is 540px
		max-width: calc(540px - 30px);
	}
	@media (min-width: 768px) {
		margin-right: 0; //so the box aligns left;
		//max-width for container at this width is 720px
		max-width:    calc(360px - 30px);
	}
	@media (min-width: 992px) {
		//max-width for container at this width is 960px
		max-width: calc(480px - 30px);
	}
	@media (min-width: 1200px) {
		//max-width for container at this width is 1140px
		max-width: calc(570px - 30px);
	}
}

// location icon
