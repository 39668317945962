.content-banner {
	margin-top:    1.5rem;
	margin-bottom: 1.5rem;
	text-align:    center;
	@media(min-width: 768px) {
		margin-top:    3rem;
		margin-bottom: 3rem;
	}
	
	img {
		margin-left:  auto;
		margin-right: auto;
		@media(min-width: 1200px) {
			max-width: 1110px; //full container width minus gutter on both sides [1140px - 2*15px]
		}
	}
}