//this is styling for hero banner in other pages
.other-hero {
	height:          auto;
	position:        relative;
	color:           $white-color;
	/*background: linear-gradient(-45deg, #61045F, #148C66, #C3007A, #0F52CF);*/
	background:      linear-gradient(135deg, $home-hero-color-1, $home-hero-color-2, $home-hero-color-3, $home-hero-color-4);
	background-size: 400% 200%;
	animation:       gradient 15s ease infinite;
	
	.no-gutters {
		padding: 2rem 2rem 0.5rem 2rem;
		@media (min-width: 992px) {
			padding: 2rem 0;
		}
		
		.other-right {
			padding:    1rem 0;
			text-align: center;
			@media (min-width: 992px) {
				padding:    3rem 0 2rem 3rem;
				text-align: left;
			}
			
			.h1-first {
				color:       #FFF;
				font-size:   2rem;
				font-weight: bold;
				@media (min-width: 575px) {
					font-weight: 600;
					font-size:   2rem;
				}
				@media (min-width: 767px) {
					font-weight: 600;
					font-size:   2rem;
				}
				@media (min-width: 992px) {
					font-weight: bold;
					font-size:   2.5rem;
				}
			}
			
			.h1-second {
				display:     block !important;
				font-size:   3rem;
				color:       #FFF;
				font-weight: bold;
				@media (min-width: 992px) {
					font-size: 4.5rem;
				}
			}
		}
	}
}

.second-other-part {
	background-color: $secondary-hover;
	padding:          1.5rem 0 2rem 0;
	@media (min-width: 992px) {
		padding: 1.5rem 0;
	}
	
	h4 {
		display:        inline;
		color:          white;
		font-style:     italic;
		font-size:      2rem;
		text-shadow:    2px 2px #000000;
		vertical-align: middle;
		//padding-bottom: 3rem;
		line-height:    1.5;
		@media (min-width: 992px) {
			padding-bottom: 0;
			font-size:      2.6rem;
		}
		
		span {
			color:       $primary-hover;
			font-weight: bold;
		}
	}
	
	.book-now-text {
		display:        inline;
		color:          white;
		font-style:     italic;
		font-size:      2rem;
		text-shadow:    2px 2px #000000;
		vertical-align: middle;
		//padding-bottom: 3rem;
		line-height:    1.5;
		@media (min-width: 996px) {
			padding-bottom: 0;
			font-size:      2.6rem;
		}
		
		span {
			color:       $primary-hover;
			font-weight: bold;
		}
	}
	
	.other-btn {
		.btn {
			padding:       0.8rem 5rem;
			border-radius: 10px;
			border:        8px solid #1D2E4C;
			margin-top:    1.5rem;
			font-size:     18px;
			@media (min-width: 1200px) {
				padding:    0.5rem 4rem;
				margin-top: 0;
			}
			
			&:hover, &:focus, &:active {
				background-color: $primary-hover !important;
				box-shadow:       none !important;
				border:           8px solid #1D2E4C !important;
			}
		}
	}
}