.btn {
	color:            $white-color;
	border-radius:    0.25rem;
	border:           2px solid $white-color;
	background-color: transparent;
	transition:       background-color 1s ease;
	padding:          5px 10px;
	@media (min-width: 768px) {
		padding: 10px 20px;
	}
	@media (min-width: 992px) {
		padding: 10px 12px;
	}
	@media (min-width: 1200px) {
		padding: 15px 30px;
	}
	
	&:hover {
		background-color: $tertiary-color;
		color:            $white-color;
	}
}

.btn-success {
	border:           2px solid $button-color;
	background-color: $button-color;
	font-size:        1rem;
	font-weight:      700;
	padding:          1.25rem 4rem;
	text-transform:   capitalize;
	
	&:hover {
		background-color: $button-hover-color;
	}
}

.btn-primary {
	border:           2px solid $button-color;
	background-color: $button-color;
	font-size:        1.3rem;
	font-weight:      700;
	
	&:hover, &:focus {
		background-color: $button-hover-color !important;
		box-shadow:       none !important;
		border-color:     $button-hover-color !important;
	}
}

.hero-text .btn-success {
	border:           2px solid $button-color;
	background-color: $button-color;
	font-size:        1rem;
	font-weight:      700;
	padding:          1.25rem 4rem;
	
	&:hover {
		background-color: $button-hover-color;
	}
}

.btn-brands {
	padding:          0;
	border:           none !important;
	background-color: $white-color;
	color:            #555;
	border-radius:    0;
	
	&:hover, &:active, &:focus {
		box-shadow:       none;
		border:           0;
		background-color: $white-color !important;
		padding:          0;
		color:            $blue-color !important;
	}
}

.btn-book {
	padding:          15px 30px;
	background-color: $red-color;
	display:          inline-block;
	line-height:      1.2;
	text-align:       center;
	border-radius:    3px;
	position:         relative;
	@media (min-width: 768px) {
		padding: 15px 60px;
	}
	
	span {
		&:first-child {
			font-weight: 600;
			font-size:   12px;
			@media (min-width: 768px) {
				font-size: 18px;
			}
		}
		
		&:last-child {
			font-size: 10px;
			@media (min-width: 768px) {
				font-size: 12px;
			}
		}
	}
	
	.btn-arrow {
		position: absolute;
		height:   60px;
		width:    auto;
		top:      -15px;
		right:    -30px;
		@media (min-width: 992px) {
			height: 75px;
		}
	}
}

.cta-box {
	border-radius:    2px;
	background-image: linear-gradient(to bottom right, #09275D 33%, #365792 50%, #09275D 66%);
	color:            $white-color;
	width:            max-content;
	padding:          15px;
	@media (min-width: 576px) {
		padding: 30px;
	}
	@media (min-width: 768px) {
		padding: 45px;
	}
	@media (min-width: 992px) {
		padding: 60px;
	}
	
	h5 {
		font-size: 26px;
	}
	
	h1 {
		font-size: 42px;
	}
}

.btn-small {
	padding:   7px 18px;
	font-size: 1rem;
}

.nav-bookcall {
	.btn-success {
		border:           2px solid $button-color;
		background-color: $button-color;
		font-size:        1rem;
		font-weight:      700;
		padding:          0.5rem 0.9rem;
		vertical-align:   middle;
		@media (min-width: 420px) {
			//font-size: 0.82rem;
		}
		@media (min-width: 576px) and (max-width: 992px) {
			margin-top:    -5px;
			margin-bottom: 5px;
			border-radius: 0 0 0.3rem 0.3rem;
		}
		@media(min-width: 768px) {
			padding: 0.5rem 0.75rem;
		}
		@media(min-width: 1200px) {
			padding: 0.625rem 2.5rem;
		}
		
		&:hover, &:focus, &:active {
			background-color: $button-hover-color !important;
			box-shadow:       none !important;
			border-color:     $button-hover-color !important;
			color: $white;
			@media (min-width: 420px) {
				//font-size: 0.875rem;
			}
			@media(min-width: 1200px) {
				//font-size: 0.875rem;
			}
		}
	}
}

.btn-more {
	border:           2px solid $button-color;
	color:            white;
	font-weight:      600;
	padding:          0.85rem 2rem;
	background-color: $button-color;
	border-radius:    0.3rem;
	
	&:focus {
		box-shadow: none;
	}
	
	&:hover {
		border:           2px solid $button-hover-color;
		background-color: $button-hover-color;
	}
}