.archive-brand {
	.left-side-brand {
		img {
			//min-height: 136%;
			left:      0;
			max-width: 200%;
			width:     auto;
		}
	}
	
	.brand-alphabets {
		margin: 1rem 0 4rem;
		
		.col-12 {
			border-top:     1px solid $light-color;
			border-bottom:  1px solid $light-color;
			padding-top:    1.5rem;
			padding-bottom: 1.5rem;
		}
		
		h5 {
			display:   inline-block;
			margin:    0 30px 0 0;
			font-size: 1rem;
		}
		
		ul {
			display:    inline-block;
			list-style: none;
			margin:     0;
			padding:    0;
			
			li {
				display: inline-block;
				margin:  0 10px;
				@media (min-width: 1200px) {
					margin: 0 13px;
				}
				
				a {
					color:       $dark-color;
					font-size:   1rem;
					font-weight: 600;
					
					&:hover {
						color: $primary-color;
					}
				}
				
				&:first-child {
					margin-left: 0;
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
		}
		
		#dropdownAlphabetsLink {
			cursor: pointer;
		}
		
		.dropdown-menu {
			height:     auto;
			max-height: 200px;
			overflow-y: scroll;
			overflow-x: hidden;
			min-width:  5rem;
			margin:     -1rem 0 0 10rem;
		}
	}
	
	.brands-loop {
		@media (min-width: 1200px) {
			margin-top: 2rem;
		}
		
		.row {
			margin-top:    0;
			margin-bottom: 0;
			
			h3 {
				font-weight: 700;
				line-height: 0.85;
			}
			
			p {
				color: $dark-color;
			}
			
			a {
				font-weight: 600;
				font-size:   1rem;
			}
			
			.btn-brands {
				padding:          0;
				border:           none !important;
				background-color: $white-color;
				color:            #555;
				border-radius:    0;
				
				&:hover, &:active {
					color:            #297CB7 !important;
					box-shadow:       none;
					padding:          0;
					background-color: $white !important;
				}
			}
		}
	}
	
	.brands-anchor {
		display:    block;
		position:   relative;
		visibility: hidden;
		top:        -145px;
	}
}