.blog-callout {
	padding:           0 2rem 2.5rem;
	margin:            3rem auto;
	border:            1px solid $primary-color;
	border-left-width: 0.25rem;
	border-radius:     0.25rem;
	
	.btn {
		margin-top:       0.25rem;
		border:           2px solid $secondary-color;
		background-color: $secondary-color;
		color:            $white;
		font-size:        1rem;
		font-weight:      700;
		padding:          0.75rem 2.75rem;
		vertical-align:   middle;
		
		&:active, &:focus, &:hover {
			background-color: $secondary-hover !important;
			box-shadow:       none !important;
			border-color:     $secondary-hover !important;
			color:            $white;
		}
	}
}

.blog-form {
	h2 {
		color:       $primary-color !important;
		font-size:   2rem;
		margin:      1.5rem 0;
		font-weight: 800;
	}
	
	h3 {
		font-size:   1.25rem;
		margin:      1rem 0;
		font-weight: 600;
	}
	
	h2, h3 {
		text-align: left;
	}
	
	p {
		color: $dark-color;
	}
	
	.btn {
		margin:           1rem 0;
		text-transform:   uppercase;
		background-color: $tertiary-color;
		
		&:hover {
			background-color: $tertiary-hover;
		}
	}
	
	.minutes {
		color: $primary-color;
	}
}