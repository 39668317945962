.location-near-me {
	background-size:     cover;
	background-repeat:   no-repeat;
	background-position: 30% center;
	padding-top:         15rem;
	padding-bottom:      2rem;
	@media (min-width: 768px) {
		background-position: center;
		padding-top:         4rem;
		padding-bottom:      4rem;
		margin-top:          5rem;
	}
	@media (min-width: 996px) {
		margin: 6rem 0;
	}
	
	.location-near-me-right {
		border-radius: 0.5rem;
		@media (min-width: 768px) {
			margin: 5rem 0;
		}
		
		.location-near-me-title {
			padding-top: 2rem;
			font-size:   2rem;
			font-weight: normal;
			color:       $black-color;
			@media (min-width: 996px) {
				font-size:   2.5rem;
				padding-top: 0;
			}
		}
		
		.location-near-me-sub_title {
			font-size:   2rem;
			font-weight: bold;
			color:       $tertiary-hover;
			@media (min-width: 996px) {
				font-size: 2.5rem;
			}
		}
		
		.location-near-me-bottom {
			color:          $black-color;
			padding-bottom: 2rem;
			@media (min-width: 996px) {
				padding-bottom: 0;
			}
			
			a {
				color:           $primary-color;
				text-decoration: none;
				font-weight:     600;
			}
		}
	}
}

.location-shortcode {
	.asp_w {
		margin: 0 auto !important;
	}
}