.location-section {
	margin-bottom:  6rem;
	padding-bottom: 3rem;
	@media(max-width: 576px) {
		background-color: #F6F6F6;
	}
	
	.text-wrapper {
		padding: 0.5rem 0 1rem;
		@media(min-width: 768px) {
			padding: 3rem 0 1rem;
		}
		@media(min-width: 992px) {
			padding: 6rem 0 0;
		}
		@media(min-width: 1200px) {
			padding: 9rem 0 3rem;
		}
		
		.text-title {
			font-size:     1.75rem;
			font-weight:   bolder;
			color:         $black-color;
			margin-bottom: 1rem;
			@media(min-width: 1200px) {
				font-size: 2rem;
			}
		}
		
		.text-subtitle {
			font-weight: bold;
			color:       $secondary-color;
			margin-top:  2rem;
			@media(min-width: 1200px) {
				font-size: 1.5rem;
			}
		}
		
		.text-description {
			a {
				color: $primary-hover;
			}
		}
		
		.ajaxsearchpro {
			margin: 0 !important;
		}
	}
	
	.oz-map {
		position: relative;
		width:    100%;
		height:   auto;
	}
	
	#ajaxsearchpro1_1 {
		width: 80%;
		@media(min-width: 768px) {
			width: 60%;
		}
	}
}