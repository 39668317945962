.pagination {
	display:    inline-flex;
	margin-top: 3rem;
	
	.page-item {
		.page-link {
			border-radius: 50px;
			margin:        5px;
			height:        37px;
			width:         38px;
		}
		
		a:hover {
			color:           $tertiary-color;
			text-decoration: none;
		}
	}
}

.page-item.active .page-link {
	z-index:          3;
	color:            $white-color;
	background-color: $primary-color;
	border-color:     $primary-color;
}

.page-link {
	position:         relative;
	display:          block;
	padding:          .5rem .75rem;
	margin-left:      -1px;
	line-height:      1.25;
	color:            $primary-color;
	background-color: $white-color;
	border:           1px solid #DEE2E6;
}

.pagination {
	padding-left:  0;
	list-style:    none;
	border-radius: .25rem;
}