.emergency {
	padding-top:    2rem;
	padding-bottom: 0;
	@media(min-width: 992px) {
		padding-top:    8rem;
		padding-bottom: 8rem;
	}
	
	.main-box {
		background:     linear-gradient(to bottom, #1E2D4A 0, #000 75%);
		padding-top:    1rem;
		padding-bottom: 1rem;
		border-radius:  0.2rem;
		color:          $white-color;
		margin-bottom:  1rem;
		@media(min-width: 992px) {
			margin-bottom:  0;
			padding-top:    2rem;
			padding-bottom: 2rem;
		}
		@media(min-width: 1200px) {
			padding-top:    2rem;
			padding-bottom: 2rem;
		}
	}
	
	.emergency-image {
		max-width: none;
		width:     auto;
		z-index:   1;
		@media(min-width: 992px) {
			top:    -50%;
			height: 200%;
			left:   -25%;
		}
		@media(min-width: 1200px) {
			top:    -58%;
			height: 215%;
			left:   -26%;
		}
	}
	
	.info {
		h3 {
			font-size:     1.3rem;
			color:         $white-color;
			font-weight:   700;
			white-space:   nowrap;
			margin-bottom: 0;
			@media(min-width: 992px) {
				font-size: 1.5rem;
			}
			@media(min-width: 1200px) {
				font-size: 1.75rem;
			}
		}
		
		p {
			font-size:     1.3rem;
			color:         $primary-hover;
			font-weight:   700;
			margin-bottom: 0;
			text-align:    center;
			@media(min-width: 992px) {
				font-size: 1.25rem;
			}
			@media(min-width: 1200px) {
				font-size: 1.5rem;
			}
		}
	}
}