//overwriting the understrap class
.wrapper {
	padding: 0;
}

*:focus {outline: none;}

// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
body {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	color:       $dark-color;
	//font-family: 'Lato', sans-serif;
	//overflow-x: hidden;
}

//animations
@keyframes gradient {
	0% {background-position: 0 50%}
	50% {background-position: 100% 50%}
	100% {background-position: 0 50%}
}

.bg-image {
	position:        absolute;
	height:          100%;
	width:           100%;
	top:             0;
	left:            0;
	object-fit:      cover;
	object-position: center;
}

.ajaxsearchpro {
	z-index: 10 !important;
}

//new font variable
h1 {
	font-size:   2.25rem;
	font-weight: 800;
}

h2 {
	font-size:   1.8rem;
	font-weight: 700;
	@media(min-width: 992px) {
		font-size: 2.2rem;
	}
}

h3 {
	font-size:   1.5rem;
	font-weight: 600;
	@media(min-width: 992px) {
		font-size: 2rem;
	}
}

h4 {
	font-size:   1.2rem;
	font-weight: 500;
	@media(min-width: 992px) {
		font-size: 1.8rem;
	}
}

h5 {
	font-size:   1.125rem;
	font-weight: 500;
}

h6 {
	font-size: 1rem;
}

p {
	font-size: 1rem;
}

.small-font {
	font-size: 0.813rem;
}

.large-font {
	font-size: 2.5rem;
}

.xl-large-font {
	font-size: 4.625rem;
}

//new color variable
.metro-top-gradient {
	background: linear-gradient(90deg, $top-gradient-color-1 35%, $top-gradient-color-2 80%);
}

.metro-text-dark-color {
	color: $black-color;
}

.metro-title-color {
	color: $black-color;
}

.metro-primary-color, .read_more {
	color: $primary-hover;
}

.read_more:hover {
	color: $primary-hover;
}

.metro-footer-bg {
	background-color: $footer-bg-color;
}

.line-height {
	line-height: 1.5rem;
}

.entry-content {
	a {
		color: $primary-hover;
		
		&:hover {
			color: $primary-color;
		}
	}
	
	p {
		font-size:   1rem;
		line-height: 1.5rem;
		//font-weight: 600;
	}
	
	h2 {
		color:       $black !important;
		font-size:   1.8rem !important;
		font-weight: 700 !important;
		margin:      2rem 0 1.3rem 0 !important;
		@media(min-width: 992px) {
			font-size: 2.2rem;
		}
	}
	
	h3 {
		color:       $black-color;
		font-size:   1.5rem;
		font-weight: 600;
		margin:      2rem 0 1.3rem 0;
		@media(min-width: 992px) {
			font-size: 2rem;
		}
	}
	
	h4, h5 {
		color:       $black !important;
		font-size:   1.4rem;
		font-weight: 500;
		margin:      1.4rem 0 1.3rem 0;
		@media(min-width: 992px) {
			font-size: 1.8rem;
		}
	}
	
	ul {
		li {
			font-weight:    600;
			line-height:    1.5;
			padding-bottom: 1rem;
		}
	}
}

.service-bg {
	background-color: $light-color;
	margin-top:       2rem;
	margin-bottom:    1rem;
	@media(min-width: 992px) {
		margin: 4rem 0;
	}
	
	.card-title {
		color: black;
	}
	
	h3 {
		margin: 0;
	}
}

// google review css
.google-banner {
	padding-top:    2rem;
	padding-bottom: 1rem;
}

// about us license css
.license-number {
	padding-bottom: 3rem;
	color:          $black-color;
	@media (min-width: 992px) {
		padding-bottom: 0;
	}
	h4 {
		color:       $black !important;
		font-size:   1.4rem;
		font-weight: 500;
		margin:      1.4rem 0 1.3rem 0;
		@media(min-width: 992px) {
			font-size: 1.7rem;
			font-weight: 700;
		}
	}
	
	strong {
		font-size:     1rem;
		padding-right: 1rem;
		color:         $grey-color;
		line-height:   1.8;
	}
}

.margin-top {
	margin-top: 8rem;
}

//alert
.alert {
	background-color: #1C1C1C;
	font-size:        .875rem;
	
	span, a {
		color: $primary-color;
	}
}

.close {
	font-size:   2.5rem;
	line-height: 0.7;
	color:       #FFF !important;
	font-weight: 300;
	cursor:      pointer;
	background:  unset;
	border:      unset;
	
	span {
		color: #FFF !important;
	}
}

.alert a:hover {
	color: unset
}

.close:hover {
	text-decoration: none;
}

.location-main {
	.home-service {
		padding: 2rem 0 5rem 0;
		
		h5 {
			color: #212529;
		}
		
		.card-title-desc {
			padding: 2rem 0 0 0;
		}
	}
}