.fa-2x:after {
	transform: scale(1.1);
}

.fa:after,
.fab:after,
.fal:after {
	/*
	background-repeat:   no-repeat;
	background-size:     contain;
	background-position: top center;*/
	display:          inline-block;
	height:           2rem;
	width:            2rem;
	content:          " ";
	mask-repeat:      no-repeat;
	mask-size:        contain;
	mask-position:    top left;
	background-color: $map-color;
	vertical-align: middle;
}

.fa-facebook-f:after,
.fa-facebook:after {
	mask-image: url("#{$img-path}/facebook-icon.png");
}

.fa-instagram:after {
	mask-image: url("#{$img-path}/instagram-icon.png");
}

.fa-linkedin-in:after,
.fa-linkedin:after {
	mask-image: url("#{$img-path}/linkedin-icon.png");
}

.fa-youtube:after {
	mask-image: url("#{$img-path}/youtube-icon.png");
}

.fa-twitter:after {
	mask-image: url("#{$img-path}/twitter-icon.png");
}

.fa-map-marker-alt:after {
	mask-image: url("#{$img-path}/map-marker.svg");
}
