.youtube-main {
	border:            solid 0.5rem transparent;
	border-radius:     0.5rem;
	background-image:  linear-gradient(#000000, #000000), linear-gradient(0deg, $primary-color 0%, $primary-hover 100%);
	background-origin: border-box;
	background-clip:   content-box, border-box;
	@media(min-width: 768px) {
		margin-top: 3rem;
	}
}

.youtube-player {
	img {
		bottom:             0;
		display:            block;
		left:               0;
		margin:             auto;
		max-width:          100%;
		width:              100%;
		position:           absolute;
		right:              0;
		top:                0;
		border:             none;
		height:             auto;
		cursor:             pointer;
		-webkit-transition: .4s all;
		-moz-transition:    .4s all;
		transition:         .4s all;
		
		&:hover {
			-webkit-filter: brightness(75%);
		}
	}
	
	.play {
		height:          56px;
		width:           80px;
		left:            50%;
		top:             50%;
		margin-left:     -40px;
		margin-top:      -28px;
		position:        absolute;
		background:      url("#{$img-path}/youtube.png") no-repeat;
		background-size: contain;
		cursor:          pointer;
		filter:          grayscale(1);
		opacity:         0.9;
		transition:      all 1s ease;
		@media(min-width: 1200px) {
			height:      70px;
			width:       100px;
			margin-left: -50x;
			margin-top:  -35px;
		}
	}
	
	&:hover {
		.play {
			filter:  grayscale(0);
			opacity: 1;
		}
	}
}