.near-me {
	padding:       3rem 1rem;
	margin-bottom: 3rem;
	@media(min-width: 576px) {
		padding: 5rem;
	}
	
	.location-title {
		font-size:   2rem;
		font-weight: 400 !important;
		color:       $white-color;
		@media (min-width: 768px) {
			font-size:   1.5rem;
			font-weight: unset !important;
		}
		@media (min-width: 992px) {
			font-size: 2rem;
		}
	}
	
	.location-sub-title {
		font-weight: 700 !important;
		color:       $white-color;
		font-size:   2rem;
		@media (min-width: 768px) {
			font-size: 2.5rem;
		}
		@media (min-width: 992px) {
			font-size: 4rem;
		}
	}
	
	.location-shortcode {
		.asp_non_compact {
			min-width: 225px !important;
		}
		
		.wpdreams_asl_container {
			padding-top:    10px;
			padding-bottom: 10px;
		}
		
		.asl_w * {
			padding-right: 10px;
		}
		
		#ajaxsearchlite1 {
			.probox {
				.proinput {
					input {
						font-size: 15px;
					}
				}
			}
		}
	}
	
	.location-text-desc {
		font-size:   1rem;
		color:       $white-color;
		padding-top: 0.5rem;
		@media (min-width: 992px) {
			padding-top: 1rem;
		}
	}
}