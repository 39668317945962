/*
* Enable and replace font names/paths as required
 */
@font-face {
	font-family:   'Poppins';
	font-weight:   300;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Poppins Light'), local('Poppins-Light'),
	               url('#{$font-path}/Poppins-Light.woff2') format('woff2'),
	               url('#{$font-path}/Poppins-Light.woff') format('woff');
}

@font-face {
	font-family:   'Poppins';
	font-weight:   normal;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Poppins Regular'), local('Poppins-Regular'),
	               url('#{$font-path}/Poppins-Regular.woff2') format('woff2'),
	               url('#{$font-path}/Poppins-Regular.woff') format('woff');
}

@font-face {
	font-family:   'Poppins';
	font-weight:   600;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Poppins SemiBold'), local('Poppins-SemiBold'),
	               url('#{$font-path}/Poppins-SemiBold.woff2') format('woff2'),
	               url('#{$font-path}/Poppins-SemiBold.woff') format('woff');
}

@font-face {
	font-family:   'Poppins';
	font-weight:   bold;
	font-style:    normal;
	font-display:  swap;
	unicode-range: U+000-5FF;
	src:           local('Poppins Bold'), local('Poppins-Bold'),
	               url('#{$font-path}/Poppins-Bold.woff2') format('woff2'),
	               url('#{$font-path}/Poppins-Bold.woff') format('woff');
}