
.trust-section {
	.col-9 {
		@media (max-width: 375px) {
			padding: 0 1rem 0 0;
		}
	}
	
	.trust-sub-title {
		font-size:   1.25rem;
		font-weight: 600;
		@media (min-width: 768px) {
			font-size: 1.75rem;
		}
	}
	
	h3 {
		font-weight: 400;
		font-style:  italic;
		font-size:   1.75rem;
	}
}