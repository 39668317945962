.awards-section {
	background-position: center;
	background-size:     cover;
	background-repeat:   no-repeat;
	//margin: 2rem 0;
	.image-center {
		p {
			margin-bottom: 0;
		}
	}
	
	.image- {
		img {
			height:      100%;
			padding-top: 0;
			@media(min-width: 992px) {
				height:      90%;
				padding-top: 10%;
			}
		}
	}
	
	.image-left {
		@extend .image-;
		@media (min-width: 768px) {
			text-align: right;
		}
	}
	
	.image-right {
		@extend .image-;
		@media (min-width: 768px) {
			text-align: left;
		}
	}
}