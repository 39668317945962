.choose-us {
	padding:    3rem 0 4rem;
	background: linear-gradient(90deg,$faq-color-1 0%,$faq-color-3 100%);
	@media(min-width: 992px) {
		padding:    5rem;
		background: linear-gradient(90deg, $faq-color-1 0%, $faq-color-2 60%, $faq-color-3 0%);
	}
	@media(max-width: 575px) {
		margin-bottom: -4rem;
	}
	
	.container-left-half {
		color: $white-color;
		@media (min-width: 768px) and (max-width: 992px) {
			//for md sizes only above 768px AND below 992px
			margin-right: auto;
			max-width:    calc(768px - 90px);
		}
		@media(min-width: 992px) {
			padding-right: 3rem;
		}
		
		h3 {
			font-size: 1.5rem;
			@media(min-width: 1240px) {
				font-size: 1.75rem;
			}
		}
	}
	
	.why-choose-image {
		height: auto;
		@media(min-width: 992px) {
			width:     450px;
			max-width: none;
		}
		@media(min-width: 1200px) {
			width: 540px;
		}
	}
	
	.bg-image {
		img {
			//height: 100%;
			//padding-right: 20%;
		}
	}
	
	.accordion {
		line-height: 1.5;
	}
	
	.card {
		background-color: transparent;
		border:           0;
		font-size:        1rem;
		border-radius:    3px !important;
		//a[aria-expanded] {
		//  &:after {
		//    font-family: "Font Awesome 5 Pro";
		//    content: "\f078";
		//    float: right;
		//  }
		//}
		//a[aria-expanded="true"]:after {
		//  content: "\f077";
		//}
		//a[aria-expanded="false"]:after {
		//  content: "\f078";
		//}
		div[aria-expanded] {
			.alldown {
				border:            solid $white-color;
				border-width:      0 4px 4px 0;
				display:           inline-block;
				padding:           3px;
				transform:         rotate(45deg);
				-webkit-transform: rotate(45deg);
				float:             right;
				margin-top:        0.5rem;
				@media(min-width: 992px) {
					border:       solid white;
					border-width: 0 4px 4px 0;
				}
			}
		}
		
		div[aria-expanded="true"] {
			.up {
				border:            solid $white-color;
				border-width:      0 4px 4px 0;
				display:           inline-block;
				padding:           3px;
				transform:         rotate(-135deg);
				-webkit-transform: rotate(-135deg);
				float:             right;
				margin-top:        0.5rem;
				@media(min-width: 992px) {
					border:       solid white;
					border-width: 0 4px 4px 0;
				}
			}
		}
		
		div[aria-expanded="false"] {
			.down {
				border:            solid $white-color;
				border-width:      0 4px 4px 0;
				display:           inline-block;
				padding:           3px;
				transform:         rotate(45deg);
				-webkit-transform: rotate(45deg);
				float:             right;
				margin-top:        0.5rem;
				@media(min-width: 992px) {
					border:       solid white;
					border-width: 0 4px 4px 0;
				}
			}
		}
		
		.card-header {
			border:           0;
			border-bottom:    1px solid $secondary-hover;
			border-radius:    0;
			padding:          0.55rem 0.25rem 0.5rem 0;
			margin:           0.5rem 0;
			font-weight:      700;
			cursor:           pointer;
			background-color: unset;
			@media(min-width: 992px) {
				border-bottom:    0;
				border-radius:    3px !important;
				padding:          0.55rem 0.75rem;
				margin:           0.5rem 0;
				background-color: $primary-hover;
			}
		}
		
		.card-body {
			padding: 0.5rem 0 1.5rem;
			@media(min-width: 992px) {
				padding: 1rem 0;
			}
			
			a {
				color:           $white-color;
				text-decoration: underline;
				
				&:hover {
					color: #CCC;
				}
			}
		}
	}
}