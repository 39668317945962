.glide {
	position:   relative;
	width:      100%;
	box-sizing: border-box; }

.glide * {
	box-sizing: inherit; }

.glide__track {
	overflow: hidden; }

.glide__slides {
	position:            relative;
	width:               100%;
	list-style:          none;
	backface-visibility: hidden;
	transform-style:     preserve-3d;
	touch-action:        pan-Y;
	overflow:            hidden;
	padding:             0;
	white-space:         nowrap;
	display:             flex;
	flex-wrap:           nowrap;
	will-change:         transform; }

.glide__slides--dragging {
	user-select: none; }

.glide__slide {
	width:                       100%;
	height:                      100%;
	flex-shrink:                 0;
	white-space:                 normal;
	user-select:                 none;
	-webkit-touch-callout:       none;
	-webkit-tap-highlight-color: transparent; }

.glide__slide a {
	user-select:       none;
	-webkit-user-drag: none;
	-moz-user-select:  none;
	-ms-user-select:   none; }

.glide__arrows {
	-webkit-touch-callout: none;
	user-select:           none; }

.glide__bullets {
	-webkit-touch-callout: none;
	user-select:           none; }

.glide--rtl {
	direction: rtl; }

.glide {
	padding-top:    3rem;
	padding-bottom: 3rem;
	
	&__slide {
		text-align: center;
		position:   relative;
		@media(max-width: 575px) {
			/*background-color: $light-color;
			opacity: 0.25;
			&--active {
					background-color: transparent;
					opacity: 1;
			}*/
		}
		@media(min-width: 576px) {
			border-radius: 0.25rem;
			&:hover {
				.brands-intro {
					opacity: 0.95;
				}
			}
		}
		
		.brands-intro {
			position:         absolute;
			opacity:          0;
			background-color: $dark-color;
			color:            $white-color;
			border-radius:    0.25rem;
			left:             0;
			right:            0;
			margin-left:      auto;
			margin-right:     auto;
			height:           100%;
			padding:          5px;
			line-height:      1.2;
			
			p {
				font-size:   0.8rem;
				font-weight: 400;
				line-height: 1.2;
			}
		}
		
		.brands-intro-sm {
			height:     125px;
			overflow:   hidden;
			text-align: center;
			color:      $dark-color;
			padding:    0 5px;
		}
		
		img {
			height: 90px;
			width:  auto;
			@media (min-width: 420px) {
				height: 120px;
			}
			@media (min-width: 768px) {
				height: 90px;
			}
			@media (min-width: 992px) {
				height: 120px;
			}
		}
	}
	
	&__arrow {
		position:   absolute;
		top:        calc(50% - 0.75rem);
		bottom:     calc(50% - 0.75rem);
		width:      1rem !important;
		opacity:    0.5;
		transition: all 1s ease;
		cursor:     pointer;
		@media(min-width: 576px) {
			width: 3rem;
		}
		
		&:hover {
			opacity:   0.66;
			transform: scale(1.1);
		}
		
		@media(max-width: 575px) {
			display: none;
		}
		
		&:after {
			height:       1rem;
			width:        1rem;
			content:      "";
			border-style: solid;
			border-width: 2px 2px 0 0;
			border-color: $black-color;
			display:      inline-block;
		}
		
		&--left {
			left: 0;
			@media(min-width: 576px) {
				left: -1rem;
			}
			
			&:after {
				transform: rotate(-135deg);
			}
		}
		
		&--right {
			right: 0;
			@media(min-width: 576px) {
				right: -1rem;
			}
			
			&:after {
				transform: rotate(45deg);
			}
		}
	}
}