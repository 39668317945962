.main-footer {
	.footer-logo {
		img {
			@media(max-width: 992px) {
				max-width: 225px
			}
		}
	}
	
	.social-icons {
		a {
			text-decoration: none;
			
			&:hover {
				filter: brightness(200%);
			}
		}
	}
	
	.footer-right-main {
		/*@media(min-width: 1200px) {
			padding-left: 5rem;
		}*/
		
		.footer-menus {
			.widget_nav_menu {
				margin-bottom: 3rem;
			}
			
			.widget-title {
				color:          $white-color;
				padding-bottom: 1rem !important;
				font-weight:    600;
			}
			
			ul {
				padding: 0;
				
				li {
					list-style: none;
					margin:     0 0 .4rem;
					
					a {
						color:           #DDDDDD;
						text-decoration: none;
						font-weight:     400;
						font-size:       1rem;
						
						&:hover {
							color: $white-color;
						}
					}
				}
			}
		}
	}
}

.metro-footer-bg-up {
	.subject {
		margin-top:    0.625rem;
		margin-bottom: 0.625rem;
		@media (min-width: 992px) {
			margin-bottom: 0;
		}
	}
}