.technology-section {
	margin-top:    3rem;
	margin-bottom: 0;
	@media(min-width: 576px) {
		margin-top:    4rem;
		margin-bottom: 1rem;
	}
	@media(min-width: 768px) {
		margin-top:    4rem;
		margin-bottom: 1rem;
	}
	@media(min-width: 992px) {
		margin-top:    6rem;
		margin-bottom: 2rem;
	}
	
	.technology-image {
		max-width: none;
		height:    auto;
		width:     100%;
		@media (min-width: 768px) {
			width: 85%;
		}
		@media(max-width: 575px) {
			margin-left:  -15px;
			margin-right: -15px;
			width:        calc(100% + 30px);
		}
	}
	
	.technology-title {
		margin-top: 2rem;
		@media (min-width: 768px) {
			margin-top: 0;
		}
	}
	
	.technology-description {
		margin-top:  1.5rem;
		font-weight: 400;
		line-height: 1.7;
		@media (min-width: 768px) {
			margin-top: 2rem;
		}
	}
}