.opening-hours {
	padding-top:    1rem;
	padding-bottom: 4rem;
	
	.title {
		color:       $black-color;
		font-weight: 600;
	}
	
	.state {
		font-weight: 400;
		color:       #8C8C8C;
	}
	
	.state-desc {
		font-size: 1rem;
	}
	
	.hours-section {
		.title {
			font-size:       1rem;
			text-decoration: underline;
			color:           $black-color;
			padding:         0;
			margin-top:      1rem;
			margin-bottom:   1rem;
		}
		
		.hours {
			color:        $primary-hover;
			padding-left: 2rem;
		}
	}
	
	.hours-male {
		width:  30%;
		bottom: -0.6rem;
		right:  0;
		@media (min-width: 768px) {
			width: 40%;
		}
		@media (min-width: 996px) {
			width: 41%;

		}
	}
}
