// Home page hero banner
.home-hero {
	height:          auto;
	position:        relative;
	color:           $white-color;
	/*background: linear-gradient(-45deg, #61045F, #148C66, #C3007A, #0F52CF);*/
	background:      linear-gradient(135deg, $home-hero-color-1, $home-hero-color-2, $home-hero-color-3, $home-hero-color-4);
	background-size: 400% 200%;
	animation:       gradient 15s ease infinite;
	
	.container {
		padding: 15px 0;
		@media (min-width: 420px) {
			padding: 30px 0;
		}
		@media (min-width: 576px) {
			padding: 45px 0;
		}
		@media (min-width: 768px) {
			padding: 60px 0;
		}
		@media (min-width: 992px) {
			//padding: 75px 0;
		}
		@media (min-width: 1200px) {
			//padding: 90px 0;
		}
		
		.no-gutters {
			padding: 2rem;
			@media (min-width: 576px) {
				padding: 0;
			}
		}
		
		.hero-text {
			.btn-success {
				padding:          0.60rem 5rem;
				font-size:        1rem;
				border-radius:    0.3rem;
				background-color: $button-color !important;
				box-shadow:       0 1px 2px 2px rgba(0, 0, 0, 0.25);
				border:           none;
				transition:       none;
				@media(min-width: 1200px) {
					padding: 0.60rem 8rem;
				}
				
				&:hover, &:focus, &:active {
					background-color: $button-hover-color !important;
					border:           none;
					box-shadow:       0 1px 4px 4px rgba(0, 0, 0, 0.25);
				}
			}
		}
		
		h1 {
			font-weight: 600;
			margin:      1rem 0;
			font-size:   1.5rem;
			@media(min-width: 768px) {
				font-size: 1.75rem;
				margin:    1rem 0;
			}
			@media(min-width: 992px) {
				font-size: 2.5rem;
			}
			
			a {
				color:       $white-color;
				font-weight: 700;
				font-size:   2.25rem;
				@media(min-width: 768px) {
					font-size: 5rem;
				}
			}
		}
		
		h2 {;
			margin: 1rem 0;
			
			a {
				color:           #FFF;
				font-weight:     300;
				font-size:       3rem;
				text-decoration: none;
				
				&:hover, &:focus {
					color:       #FFF;
					text-shadow: 0 2px 2px #1C2739;
					transition:  ease-in .3s;
				}
				
				@media(min-width: 992px) {
					font-size: 4rem;
				}
				@media(min-width: 1200px) {
					font-size: 4.5rem;
				}
			}
		}
		
		h3 {
			font-size:   1.75rem;
			margin:      0;
			font-weight: 400;
			@media(min-width: 768px) {
				font-size: 2.625rem;
			}
		}
		
		p {
			margin: 0.9375rem 0 0 0;
		}
		
		small {
			font-size:   1rem;
			font-weight: 400;
		}
	}
}

#homeCarousel {
	.carousel-item {
		min-height:       250px;
		background-color: $tertiary-color;
	}
	
	.container {
		position: relative;
	}
	
	.hero-badge, .hero-human {
		position: absolute;
	}
	
	.hero-human {
		bottom: 0;
		left:   0;
		@media (min-width: 1200px) {
			left: 100px;
		}
		
		img {
			height: 350px;
			//height: 90vh;
			width:  auto;
			@media (min-width: 1200px) {
				height:     30vw;
				max-height: 500px;
			}
		}
	}
	
	.hero-badge {
		img {
			height:   130px;
			width:    auto;
			position: relative;
			z-index:  20;
			top:      -25px;
			right:    45px;
		}
	}
	
	.cta-box {
		position:  absolute;
		top:       -380px;
		right:     15px;
		padding:   1.875rem 1.875rem 1.875rem 3.75rem;
		width:     auto;
		min-width: 550px;
		@media (min-width: 1200px) {
			right: 100px;
			top:   -420px;
		}
		
		h5 {
			margin-bottom: 1.1875rem;
		}
		
		h1 {
			font-size:   2.25rem;
			font-weight: 700;
			white-space: nowrap;
		}
		
		h5 {
			.text-yellow {
				font-size:   3.125rem;
				font-weight: 700;
			}
		}
		
		.btn-book {
			padding: 0.9375rem 7.5rem;
			
			.text-yellow {
				font-size:   1.75rem;
				font-weight: 700
			}
		}
	}
}