.book-form-main {
	background: linear-gradient(180deg, #21314a 90%, $light-color 0%);
	
	.bg-image {
		object-position: bottom;
	}
	
	.book-form-right {
		padding: 2rem;
		@media (min-width: 996px) {
			padding: 5rem 2rem 10rem 0;
		}
		
		h4 {
			color:       $white-color;
			font-size:   2rem;
			font-weight: 700;
			
			span {
				color: $primary-color;
			}
		}
		
		p {
			color:       $white;
			padding-top: 1.2rem;
		}
	}
	
	.mauticform_wrapper {
		max-width:        100% !important;
		//margin: 16px auto !important;
		background:       linear-gradient(180deg, #FAFAFA 38%, #F6F6F6 58%, #EEE 100%, #E9E9E9 100%);
		//background-color: rgba(0, 0, 0, 0);
		background-color: $light;
		box-shadow:       0 0 10px -5px $grey-color;
		display:          flex !important;
		padding:          1.5rem;
		
		form {
			width: 100%;
		}
		
		.row {
			display: unset !important;
			margin:  0;
		}
		
		h3 {
			margin-bottom: 1.3rem;
			font-size:     1.5rem;
			font-weight:   700;
			
			&:after {
				display:          block;
				content:          " ";
				height:           0.25rem;
				width:            2rem;
				background-color: $primary-color;
				border-radius:    0.25rem;
				margin-top:       0.3rem;
			}
		}
		
		h5 {
			margin-left:   0.25rem;
			margin-top:    1rem;
			margin-bottom: 0.5rem;
		}
		
		.form-control {
			box-shadow:    0 0 0 $white inset;
			border-radius: .3rem;
			color:         #939393;
			font-size:     1rem;
		}
		
		.form-control:focus-visible {
			text-shadow: none;
			color:       $black-color;
		}
		
		.selecttime1 {
			color: #7C848B;
		}
		
		.btn {
			width:            100%;
			text-transform:   unset;
			padding-top:      0.75rem;
			padding-bottom:   0.75rem;
			font-size:        1rem;
			font-weight:      800;
			color:            $white;
			background-color: $primary-hover;
			border:           none;
			margin-left:      -15px;
			
			&:hover {
				background-color: $primary-color;
			}
		}
		
		.in-touch {
			margin-top: 0.75rem;
			@media(min-width: 768px) {
				margin-top:  0.25rem;
				line-height: 1.2;
			}
		}
		
		.minutes {
			color: $primary-hover;
		}
		
		textarea.form-control.is-valid, textarea.form-control.is-invalid {
			padding-right:       calc(1.5em + .75rem);
			background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
		}
	}
}

.page-template-book-now .book-form-main {
	background: unset;
	
	.in-touch {
		@media(min-width: 992px) {
			margin-top: 0.75rem;
		}
	}
}

.page-contact .book-form-main {
	background: unset;
	
	h3:after {
		left: 15px;
	}
	.btn{
		margin-left: 0;
	}
}

.field-hidden1 {
	display:    none !important;
	visibility: hidden !important;
}

.mauticform-errormsg {
	display: none; //hide Mautic Error message as we're now using Bootstrap5 styled error message
}

.is-invalid {
	background-image:    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
	background-repeat:   no-repeat;
	border-color:        #FB3048;
	background-position: right calc(.375em + .1875rem) center;
	background-size:     calc(.75em + .375rem) calc(.75em + .375rem);
}

.is-valid {
	background-image:    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=") !important;
	background-repeat:   no-repeat;
	border-color:        #28A745;
	background-position: right calc(.375em + .1875rem) center;
	background-size:     calc(.75em + .375rem) calc(.75em + .375rem);
}

select {
	&.form-control {
		color:               #6C757D;
		background-image:    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
		background-repeat:   no-repeat;
		background-position: right .75rem center;
		background-size:     16px 12px;
		-webkit-appearance:  none;
		-moz-appearance:     none;
		appearance:          none;
	}
}