.custom-banner {
	padding:       1rem;
	margin-bottom: 5rem;
	@media(min-width: 576px) {
		padding: 5rem;
	}
	@media(max-width: 576px) {
		padding:    1rem;
		box-shadow: 0 .05rem .4rem 0 rgba(0, 0, 0, .1);
	}
	
	.call-banner-left {
		background-color: $white-color;
		padding:          2rem 1rem;
		border-radius:    0.5rem;
		@media(min-width: 768px) {
			padding: 3rem;
		}
		
		p {
			padding: 1rem 0;
		}
	}
	
	.call-banner-right {
		padding:    0 0 4rem;
		text-align: center;
		@media(min-width: 992px) {
			padding: 6rem 0 6rem 3rem;
		}
	}
}

.call-banner-mobile {
	width:     100%;
	max-width: none;
}
