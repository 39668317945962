.location-main {
	.entry-title {
		font-size:   1.75rem;
		font-weight: 700;
		color:       $secondary-color;
		@media(min-width: 768px) {
			font-size: 40px;
		}
	}
	
	.youtube-wrapper {
		width:          100%;
		padding-bottom: 54.25%;
		padding-top:    15px;
		margin-bottom:  1rem;
		@media(min-width: 768px) {
			float:          left;
			width:          320px;
			margin-right:   15px;
			padding-bottom: 28.25%;
			padding-top:    0;
			margin-bottom:  0;
		}
		@media(min-width: 968px) {
			width:        450px;
			margin-right: 30px;
		}
	}
	
	.entry-content {
		//margin-bottom: 84px;
		.social {
			margin-top: 35px;
			
			.fa {
				background-color: $secondary-color;
				border-radius:    25px;
				color:            #FFF;
				display:          inline-block;
				height:           25px;
				line-height:      25px;
				margin:           auto 3px;
				width:            25px;
				font-size:        10px;
				text-align:       center;
			}
			
			.fa-twitter {
				background-color: #329FF5;
			}
			
			.fa-facebook {
				background-color: #3B5A96;
			}
			
			.fa-instagram {
				background-color: #0078B8;
			}
			
			.fa-share-alt {
				background-color: $white-color;
				color:            $black-color;
			}
			
			.related-suburb {
				color:           $dark-color;
				text-decoration: underline;
				font-size:       14px;
				padding-right:   6px;
			}
		}
		
		.google-map-main {
			padding-top: 35px;
		}
	}
	
	.location-services {
		//background-color: $light-color;
		background-color: #F8F8F8;
	}
}

.map-container {
	overflow: hidden;
	height:   100%;
}

.map-container iframe {
	left:     0;
	top:      0;
	height:   100%;
	width:    100%;
	position: absolute;
	@media (min-width: 768px) {
		overflow:      hidden;
		border-radius: 0 15px 15px 0;
	}
}

.main-map {
	top: 32%;
	
	h1 {
		font-size:   3.75rem;
		font-weight: 700;
		color:       $white-color;
		top:         50%;
		left:        50%;
		position:    absolute;
		transform:   translate(-50%, -50%);
	}
}

.masthead {
	height:              9rem;
	background-size:     cover;
	background-position: center;
	background-repeat:   no-repeat;
	width:               100%;
	overflow:            hidden;
	@media(min-width: 768px) {
		height: 25rem;
	}
}

.location-title {
	font-size:   2rem;
	font-weight: 700 !important;
	color:       $white-color;
	//background-color: rgba(0,0,0,0.5);
	padding:     0;
	display:     inline-block;
	@media (min-width: 576px) {
		font-size: 2.75rem;
	}
	@media (min-width: 768px) {
		font-size: 4rem;
	}
	@media (min-width: 992px) {
		font-size: 4.5rem;
	}
}

.location-subtitle {
	font-size:   1rem;
	font-weight: 400;
	color:       $white-color;
	display:     block;
	@media (min-width: 576px) {
		font-size: 1.5rem;
	}
	@media (min-width: 768px) {
		font-size:   2rem;
		font-weight: 600;
	}
}

.hm-serv-desc {
	display: none;
}

.intro-sell {
	li {
		@media(max-width: 576px) {
			display: flex;
		}
	}
	
	.fa-shield-check {
		@media(max-width: 576px) {
			font-size: 2.15rem !important;
			&:before {
				//vertical-align: sub;
			}
		}
	}
}