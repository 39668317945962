.introduction-section {
	background-color: $light-color;
	padding:          3rem 0 0 0;
	@media(min-width: 992px) {
		padding: 4rem 0 3rem 0;
	}
	
	.left-side-intro {
		.img-border {
			border:        0.4rem solid $white-color;
			border-radius: 0.4rem;
			
			.left-side-img {
				border-radius: 0.4rem;
			}
		}
	}
	
	.right-side-intro {
		padding-left:   1rem;
		padding-bottom: 1rem;
		@media(min-width: 992px) {
			padding-left:   2rem;
			padding-bottom: 0;
		}
		
		h2 {
			color:       $black-color;
			font-size:   2.2rem;
			font-weight: bold;
			padding-top: 2rem;
			@media(min-width: 768px) {
				padding-top: 0;
				font-size:   2.1rem;
			}
			@media(min-width: 992px) {
				font-size:   3rem;
				padding-top: 0;
			}
			
			span {
				color: $primary-hover;
			}
		}
		
		p {
			padding-top: 1rem;
			font-weight: 600;
		}
		
		img {
			margin-top:    1rem;
			margin-bottom: 1.5rem;
			@media(min-width: 992px) {
				margin-bottom: 0;
			}
		}
		
		ul {
			color:        #FFF;
			padding-left: 0;
			list-style:   none;
			padding-top:  1rem;
			
			li {
				position:      relative;
				color:         $black-color;
				font-weight:   600;
				margin-bottom: 0.5rem;
				font-size:     1.2rem;
				display:       block;
				padding-left:  2rem;
				@media(min-width: 768px) {
					font-size: 1rem;
				}
				@media(min-width: 996px) {
					font-size: 1.2rem;
				}
				
				&:before {
					position:         absolute;
					content:          "";
					mask-image:       url("#{$img-path}/shield-bullet.png");
					mask-repeat:      no-repeat;
					mask-position:    top left;
					height:           1.5rem;
					width:            1.5rem;
					left:             0;
					top:              15%;
					background-color: $primary-color;
				}
			}
		}
	}
	
	.btn {
		width:  100%;
		margin: 15px 0px 20px;
		@media (min-width: 768px) {
			width: 50%;
		}
	}
}

//single brand page css
.single-brands-intro {
	.introduction-section {
		@media (min-width: 768px) {
			padding: 3rem 0 2rem 0;
		}
		@media(min-width: 992px) {
			padding: 3rem 0;
		}
		
		.right-side-intro {
			@media (min-width: 992px) {
				min-height: 16rem;
			}
		}
	}
	
	.btn {
		@media (min-width: 768px) {
			margin-top: 2rem !important;
		}
	}
}

.employee-main {
	//position: relative;
	.employee-img {
		position: absolute;
		z-index:  1;
		//width: 50%;
		right:    0.2%;
		bottom:   -0.2rem;
		height:   18rem;
		@media (min-width: 992px) {
			right:  -2%;
			bottom: -54px;
			height: auto;
		}
	}
}

.semi-circle-wrapper-intro {
	height:           100px;
	width:            100%;
	overflow:         hidden;
	background-color: white;
	position:         relative;
	@media(min-width: 992px) {
		margin-top:    -3rem;
		margin-bottom: 3rem;
	}
	
	.semi-circle-intro {
		position:         absolute;
		background-color: $light-color;
		height:           500vw;
		width:            500vw;
		border-radius:    50%;
		bottom:           0;
		left:             -200vw;
		@media(min-width: 2000px) {
			height: 1000vw;
			width:  1000vw;
			left:   -450vw;
		}
	}
}

//bottom Space
.bottom-space {
	margin-bottom: 5rem;
	@media (min-width: 992px) {
		margin-bottom: 8rem;
	}
}