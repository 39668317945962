.state-main {
	.state-intro {
		border-radius:    15px;
		background-color: #F3F3F3;
		
		.bg-image {
			border-radius:       15px 0 0 15px;
			background-position: center;
		}
	}
}

.suburb-char {
	margin:      2rem 0 .5rem .25rem;
	font-weight: 700;
}

.suburb-name {
	color:   $grey-color;
	margin:  .15rem .25rem;
	padding: 0;
	display: inline-block;
}