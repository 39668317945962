//top nav SCSS file
.top-gradient {
	height:           5px;
	background-color: $tertiary-color;
	//background-image: linear-gradient(to right, $secondary, $tertiary);
	background-image: linear-gradient(90deg, $top-gradient-color-1 35%, $top-gradient-color-2 100%);
}

.header-service {
	//font-weight: 400;
	letter-spacing: 0.03rem;
}

.top-nav {
	background-color: $black-color;
	color:            $white-color;
	font-size:        1rem;
	font-weight:      600;
	padding:          0.3125rem 0;
	line-height:      2.7;
	@media (min-width: 992px) {
		padding: 0
	}
	@media (min-width: 420px) {
		font-size: 0.625rem;
	}
	@media (min-width: 1200px) {
		font-size: 0.75rem;
	}
	
	.metro-front {
		font-weight: bold;
	}
	
	a {
		&:hover {
			color: #CCC;
		}
	}
	
	ul {
		margin:  0;
		padding: 0;
		/* font-weight: 600;*/
		li {
			display:        inline-block;
			vertical-align: middle;
			text-align:     right;
			/*line-height: 1.4;*/
			&:hover {
				//background-color: #21293d
				text-decoration: underline;
			}
			&:after{
				content: "";
				height: 1rem;
				display: inline-block;
				position: absolute;
				top: 13px;
				right: auto;
				border-right: 2px solid $grey-color;
			}
			&:last-child{
				&:after{
					border-right: none;
				}
			}
			
			a {
				text-decoration: none;
				outline:         none;
				color:           #B7C2C7;
				text-transform:  capitalize;
				padding:         0.3125rem 0.625rem;
				letter-spacing:  0.03rem;
				
				&:hover {
					text-decoration: none;
					color:           #EEE;
				}
			}
		}
	}
	
	#metropolitan-menu {
		.active {
			background-color: $tertiary-hover;
		}
		
		.right-bar {
			&:after {
				content:      "";
				height:       1rem;
				display:      inline-block;
				position:     absolute;
				top:          13px;
				right:        auto;
				border-right: 2px solid $dark-color;
			}
		}
	}
	
	.active {
		a {
			color: white
		}
	}
	
	.click-disable {
		pointer-events: none;
	}
	
	.nav-bookcall {
		line-height: 1;
		
		.hours {
			height:         40px;
			width:          auto;
			vertical-align: middle;
			@media (min-width: 576px) {
				height: 40px;
			}
		}
		
		.nav-phone {
			font-size:      1rem;
			padding-left:   0.3rem;
			font-weight:    500;
			vertical-align: middle;
			@media (min-width: 376px) {
				font-size:    1.5rem;
				padding-left: 0.25rem;
				font-weight:  700;
			}
			@media (min-width: 576px) {
				font-size:    1.8rem;
				padding-left: 0.8rem;
			}
		}
		
		.btn-small {
			@media (max-width: 576px) {
				position:      absolute;
				top:           -5px;
				right:         0;
				height:        50px;
				border-radius: 0;
				width:         150px;
				padding-top:   0.75rem;
			}
		}
	}
	
	.hours {
		max-width: 30px;
	}
	
	.nav-phone {
		color:           white;
		font-weight:     bold;
		font-size:       1rem;
		text-decoration: none;
		vertical-align:  middle;
	}
}
