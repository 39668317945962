@media (min-width: 768px) and (max-width: 991px) {
	/* Show 4th slide on md if col-md-4*/
	.carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
		position:   absolute;
		top:        0;
		right:      -33.3333%; /*change this with javascript in the future*/
		z-index:    -1;
		display:    block;
		visibility: visible;
	}
}

@media (min-width: 576px) and (max-width: 768px) {
	/* Show 3rd slide on sm if col-sm-6*/
	.carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
		position:   absolute;
		top:        0;
		right:      -50%; /*change this with javascript in the future*/
		z-index:    -1;
		display:    block;
		visibility: visible;
	}
}

@media (min-width: 576px) {
	.carousel-item {
		margin-right: 0;
	}
	/* show 2 items */
	.carousel-inner .active + .carousel-item {
		display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
		transition: none;
	}
	.carousel-inner .carousel-item-next {
		position:  relative;
		transform: translate3d(0, 0, 0);
	}
	/* left or forward direction */
	.active.carousel-item-left + .carousel-item-next.carousel-item-left,
	.carousel-item-next.carousel-item-left + .carousel-item,
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(-100%, 0, 0);
		visibility: visible;
	}
	/* farthest right hidden item must be also positioned for animations */
	.carousel-inner .carousel-item-prev.carousel-item-right {
		position:   absolute;
		top:        0;
		left:       0;
		z-index:    -1;
		display:    block;
		visibility: visible;
	}
	/* right or prev direction */
	.active.carousel-item-right + .carousel-item-prev.carousel-item-right,
	.carousel-item-prev.carousel-item-right + .carousel-item,
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(100%, 0, 0);
		visibility: visible;
		display:    block;
		visibility: visible;
	}
}

/* MD */
@media (min-width: 768px) {
	/* show 3rd of 3 item slide */
	.carousel-inner .active + .carousel-item + .carousel-item {
		display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
		transition: none;
	}
	.carousel-inner .carousel-item-next {
		position:  relative;
		transform: translate3d(0, 0, 0);
	}
	/* left or forward direction */
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(-100%, 0, 0);
		visibility: visible;
	}
	/* right or prev direction */
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(100%, 0, 0);
		visibility: visible;
		display:    block;
		visibility: visible;
	}
}

/* LG */
@media (min-width: 991px) {
	/* show 4th item */
	.carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
		display: block;
	}
	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
		transition: none;
	}
	/* Show 5th slide on lg if col-lg-3 */
	.carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
		position:   absolute;
		top:        0;
		right:      -25%; /*change this with javascript in the future*/
		z-index:    -1;
		display:    block;
		visibility: visible;
	}
	/* left or forward direction */
	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(-100%, 0, 0);
		visibility: visible;
	}
	/* right or prev direction //t - previous slide direction last item animation fix */
	.carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
		position:   relative;
		transform:  translate3d(100%, 0, 0);
		visibility: visible;
		display:    block;
		visibility: visible;
	}
}

#brands-carousel {
	padding-top:    3rem;
	padding-bottom: 3rem;
	
	.brand-images {
		//max-width: 300px !important;
		height: auto;
		width:  100%;
		margin: 15px;
		@media (min-width: 420px) {
			height: 90px;
			width:  auto;
		}
		@media (min-width: 992px) {
			height: 115px;
			width:  auto;
		}
	}
	
	.carousel-item {
		border-radius: 0.25rem;
		
		&:hover {
			background-color: $black-color;
		}
	}
	
	.brands-intro {
		height:        100px;
		width:         100%;
		overflow:      hidden;
		padding:       0.25rem 0.5rem;
		color:         $gray-600;
		border-radius: 0.25rem;
		text-align:    center;
		@media(min-width: 992px) {
			padding: 0.5rem 1rem;
		}
		
		p {
			margin:      0;
			font-size:   1rem;
			line-height: 1.2;
		}
	}
	
	.carousel-control-next,
	.carousel-control-prev {
		top:        calc(50% - 0.75rem);
		bottom:     calc(50% - 0.75rem);
		width:      2rem;
		opacity:    0.5;
		transition: all 1s ease;
		@media(min-width: 576px) {
			width: 3rem;
		}
		
		&:hover {
			opacity:   0.66;
			transform: scale(1.1);
		}
	}
	
	.carousel-control-next {
		right: 0;
		@media(min-width: 576px) {
			right: -2rem;
		}
	}
	
	.carousel-control-prev {
		left: 0;
		@media(min-width: 576px) {
			left: -2rem;
		}
	}
	
	.carousel-control-next-icon,
	.carousel-control-prev-icon {
		height:           2rem;
		width:            2rem;
		content:          "";
		border-style:     solid;
		border-width:     2px 2px 0 0;
		border-color:     $black-color;
		display:          inline-block;
		background-image: none;
	}
	
	.carousel-control-next-icon {
		//background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
		transform: rotate(45deg);
	}
	
	.carousel-control-prev-icon {
		//background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
		transform: rotate(-135deg);
	}
}