.message-wrapper {
	position:         absolute;
	bottom:           6rem;
	background-color: $dark-color;
	color:            #FFF;
	font-weight:      600;
	border-radius:    1rem;
	width:            50%;
	@media(min-width: 450px) {
		bottom: 8rem;
	}
	@media(min-width: 576px) {
		bottom: 9rem;
		width:  60%;
	}
	@media(min-width: 768px) {
		width: 70%;
	}
	@media(min-width: 992px) {
		bottom: 9.5rem;
		right:  136px;
		width:  28%;
	}
	@media(min-width: 1200px) {
		width: 31%;
	}
	@media(max-width: 991px) {
		right: 12rem;
	}
	@media(max-width: 575px) {
		right: 10rem;
	}
	
	.message-box {
		position: relative;
		padding:  1rem;
		@media(min-width: 992px) {
			padding:    1.5rem 1.5rem 1.5rem 1rem;
			text-align: left;
		}
		@media(min-width: 1200px) {
			padding: 1.5rem 3rem 1.5rem 1rem;
		}
		
		.message-text {
			&:after {
				position:    absolute;
				content:     "";
				bottom:      -40px; //matching border-top of this
				right:       5rem;
				border-top:  40px solid $dark-color;
				border-left: 35px solid transparent;
			}
			
			p {
				margin: 0;
			}
		}
	}
}

.message-girl {
	float: right;
	@media(min-width: 992px) {
		position: absolute;
		bottom:   0;
		right:    -1rem;
	}
	@media(min-width: 1200px) {
		right: 0;
	}
	@media(max-width: 575px) {
		margin-right: -2rem;
	}
}

.footer-payicons {
	max-height: 25px;
	height:     auto;
	width:      auto;
}

.footer-find-near {
	.fa-map-marker-alt {
		@media(max-width: 767px) {
			display: block;
		}
		
		&:after {
			height:         2.25rem;
			width:          2.25rem;
			@media(min-width: 768px) {
				position: absolute;
				margin-top: 0.25rem;
			}
		}
	}
	
	a {
		text-decoration: none;
		color:           $white-color;
	}
	
	.footer-find-near-main {
		line-height: 1.4rem;
		
		img {
			margin:    0 auto;
			max-width: 1.25rem;
			@media(min-width: 768px) {
				float:  left;
				margin: 0.375rem 0.625rem 1rem auto;
			}
		}
	}
	
	span {
		font-weight: 600;
		display:     inline-block;
		@media(min-width: 768px) {
			display: block !important;
		}
	}
}

.social-icons {
	a:hover {
		text-decoration: none;
	}
}

.license-number-footer {
	color:      #808080;
	text-align: center;
	@media (min-width: 992px) {
		text-align: left;
	}
	
	strong {
		font-size:   0.75rem;
		color:       #979797;
		line-height: 1.8;
	}
	
	ul {
		list-style: none;
		padding:    0;
		margin:     0;
	}
	
	li {
		display: block;
		@media (min-width: 576px) {
			display: inline-block;
		}
	}
	
	span {
		display: none;
		padding: 0 .5rem;
		@media (min-width: 576px) {
			display: inline-block;
		}
	}
}