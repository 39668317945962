.service-banner {
	background-color: $light-color;
	
	.service-text {
		margin: 3rem 0;
		@media(min-width: 992px) {
			margin: 5rem 0;
		}
	}
	
	.service-title {
		font-size: 2rem;
	}
	
	p {
		margin:      1rem 0 1.5rem;
		line-height: 1.6;
	}
}