.above-points {
	background-position: center;
	background-repeat:   no-repeat;
	color:               $dark-color;
	text-align:          center;
	background-size:     cover;
	padding:             30px 0 0;
	position:            relative;
	z-index:             2;
	@media(min-width: 768px) {
		padding: 45px;
	}
	@media(min-width: 992px) {
		padding:         4rem 0 0;
		background-size: cover;
	}
	
	p {
		margin: 20px 0 0;
	}
	
	.mb-3 {
		font-size: 1.25rem;
	}
	
	.review-image {
		@media(min-width: 768px) {
			padding-top: 0;
		}
	}
	
	.col-md-8 {
		padding-top: 2rem;
		@media(min-width: 768px) {
			padding-top: 3rem;
		}
	}
	
	.emp-img {
		left:    0;
		z-index: 1;
		bottom:  -70px;
		@media(min-width: 992px) {
			bottom: -49px;
		}
		@media(min-width: 1600px) {
			bottom: -56px;
		}
		@media(min-width: 2000px) {
			bottom: -75px;
		}
	}
}

.semi-circle-wrapper {
	height:           100px;
	width:            100%;
	overflow:         hidden;
	background-color: $light-color;
	position:         relative;
	margin-top:       -4rem;
	@media(min-width: 992px) {
		margin-top: -2rem;
	}
	
	.semi-circle {
		position:         absolute;
		background-color: $white-color;
		height:           500vw;
		width:            500vw;
		border-radius:    50%;
		bottom:           0;
		left:             -200vw;
		z-index:          1;
		@media(min-width: 2000px) {
			height: 1000vw;
			width:  1000vw;
			left:   -450vw;
		}
	}
}

.box-below {
	background-color: $light-color;
	//background-image: linear-gradient(#e8e8e8 0%, #e8e8e8 25%, #ffffff 75%);
	min-height:       1rem;
	height:           auto;
	width:            100%;
}

.page-mode-selling {
	padding-bottom: 2rem;
	@media(min-width: 992px) {
		padding-bottom: 4rem;
	}
	
	.home-points {
		margin-bottom: -8rem;
		@media(min-width: 768px) {
			margin-bottom: -2rem;
		}
	}
}

.home-points {
	background:  linear-gradient($light-color 0, $light-color 65%, $white-color 90%);
	padding-top: 6rem;
	@media(min-width: 992px) {
		background: linear-gradient($light-color 0, $light-color 25%, $white-color 60%);
	}
	@media(max-width: 992px) {
		margin-bottom: -6rem;
	}
	@media(max-width: 768px) {
		margin-bottom: -2rem;
	}
	
	.points-wrapper {
		background-color: $white-color;
		position:         relative;
		text-align:       center;
		padding:          2rem;
		margin-bottom:    6rem;
		border:           0.15rem solid #CDCDCD;
		@media(min-width: 992px) {
			padding:       1rem;
			margin-bottom: 0;
			border:        unset;
		}
		@media(min-width: 1200px) {
			padding: 2rem;
		}
		
		h3 {
			font-size: 1.5rem;
			@media(min-width: 1200px) {
				padding: 0 1.25rem;
			}
		}
		
		img {
			margin-top:     -5rem;
			padding-bottom: 1rem;
		}
		
		.btn {
			border-radius: 0.3rem;
		}
	}
}