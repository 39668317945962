.testimonial-section {
	padding-top: 3rem !important;
	@media(min-width: 992px) {
		padding-top: 1.5rem !important;
	}
	
	.testimonials-subtitle {
		color:     $dark-color;
		font-size: 1.2rem;
		@media(min-width: 992px) {
			font-size: 1.75rem;
		}
	}
	
	.testimonials-description {
		line-height: 1.5;
		margin:      2.5rem auto 2rem;
		@media(min-width: 992px) {
			margin: 2.5rem auto 4rem;
		}
	}
	
	.card-text {
		background-color: $light-color;
		padding:          2rem;
		border-radius:    0.5rem;
	}
	
	.read_more {
		cursor: pointer;
		
		&:hover {
			text-decoration: none !important;
		}
	}
	
	.read-more-dots {
		color: #000;
	}
}