.thankyou_wrapper {
	background-color: $light-color;
	background-image: linear-gradient(180deg, #FAFAFA 38%, #F6F6F6 58%, #EEE 100%, #E9E9E9 100%);
	border-radius:    0.625rem;
	border:           1px solid $light-color;
	box-shadow:       0 0 0.625rem -0.25rem $grey-color;
	margin-bottom:    1rem;
	
	.thankyou_inner {
		border:        0.25rem solid #FFF;
		border-radius: 0.625rem;
		padding:       3rem 2rem;
	}
	
	h1 {
		color:     $primary-hover;
		font-size: 2rem;
		margin:    0.5rem auto;
	}
	
	p {
		color:         $dark-color;
		margin-top:    1rem;
		margin-bottom: 2rem;
	}
	
	@media (min-width: 768px) {
		h1 {
			font-size: 3.5rem;
		}
	}
}