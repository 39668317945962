.section-accordion {
	margin-top:    3rem;
	margin-bottom: 3rem;
	@media (min-width: 992px) {
		margin-bottom: 5rem;
	}
	
	.card {
		border:           none;
		border-radius:    0;
		margin:           1.5rem 0;
		background-color: transparent;
		line-height:      1.6;
		border-bottom:    1px solid $tertiary-color !important;
		
		&.active {
			.card-header span:after {
				content: "-";
			}
		}
	}
	
	ul {
		margin: 1rem 0 0;
	}
	
	.card-header {
		background-color: transparent;
		border-bottom:    none;
		position:         relative;
		padding-right:    2.25rem;
		
		span:after {
			content:  "+";
			position: absolute;
			top:      0.75rem;
			right:    1.125rem;
		}
		
		.accordion-link {
			color:       $primary-hover;
			cursor:      pointer;
			font-size:   1.25rem;
			font-weight: 600;
			@media(min-width: 768px) {
				font-size: 1.5rem;
			}
		}
	}
	
	.card-body {
		padding-top: 0;
	}

	h2{
		margin: 0 0 1.3rem 0 !important;
		font-size: 1.8rem;
		color: $black;
	}
}
