.festival-section-mobile {
	width:     100%;
	max-width: none;
}

.festival-section {
	margin-bottom: 0;
	
	.left-section {
		background-color: #10142C;
		padding:          3rem 1rem;
		@media(min-width: 992px) {
			padding: 4rem 1rem;
		}
		
		h3 {
			color: $white-color;
			@media(min-width: 768px) {
				font-size:   2.25rem;
				font-weight: 700;
			}
		}
		
		p {
			color:   $white-color;
			padding: 1rem 0;
			@media(min-width: 768px) {
				padding: 1rem 2rem 1rem 0;
			}
		}
		
		.btn {
			padding:   .75rem 2.5rem;
			font-size: 1rem;
		}
	}
}