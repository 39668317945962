/*
 * THEME VARIABLES
 */
/* = Paths = */
/* = Theme Colours = */
/* = Generic Colours = */
/* = Fonts Colours = */
/* = social colors = */
/*
* Enable and replace font names/paths as required
 */
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Poppins Light"), local("Poppins-Light"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"); }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only, .screen-reader-text {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto,
.aligncenter {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto,
.aligncenter {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.wrapper {
  padding: 30px 0; }

#wrapper-hero {
  padding: 0px !important; }

.sticky,
.gallery-caption,
.bypostauthor {
  font-size: inherit; }

.wrapper#wrapper-sticky {
  border-bottom: 1px solid #dee2e6; }

#wrapper-footer-full,
#wrapper-static-hero {
  background-color: #e9ecef; }

.wp-caption {
  font-size: inherit; }

.wp-caption-text {
  font-size: inherit; }

.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em; }

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em; }

.entry-footer span {
  padding-right: 10px; }

img.wp-post-image,
article img,
figure,
img,
#secondary img {
  max-width: 100%;
  height: auto; }

a.skip-link {
  position: fixed;
  z-index: 1000;
  top: 0px;
  right: 0px; }

.jumbotron {
  margin-bottom: 0px; }

.navbar-dark .navbar-nav .dropdown-menu .nav-link {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529 !important;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .navbar-dark .navbar-nav .dropdown-menu .nav-link:hover, .navbar-dark .navbar-nav .dropdown-menu .nav-link:focus {
    color: #16181b !important;
    text-decoration: none;
    background-color: #e9ecef; }
  .navbar-dark .navbar-nav .dropdown-menu .nav-link.active, .navbar-dark .navbar-nav .dropdown-menu .nav-link:active {
    color: #fff !important;
    text-decoration: none;
    background-color: #007bff; }
  .navbar-dark .navbar-nav .dropdown-menu .nav-link.disabled, .navbar-dark .navbar-nav .dropdown-menu .nav-link:disabled {
    color: #adb5bd !important;
    background-color: transparent; }

.navbar-light .navbar-brand a {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand a:hover, .navbar-light .navbar-brand a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand a {
  color: #fff; }
  .navbar-dark .navbar-brand a:hover, .navbar-dark .navbar-brand a:focus {
    color: #fff; }

.navbar h1 {
  font-weight: 400; }

.wrapper {
  padding: 0; }

*:focus {
  outline: none; }

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #3D4144; }

@keyframes gradient {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

.bg-image {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center; }

.ajaxsearchpro {
  z-index: 10 !important; }

h1 {
  font-size: 2.25rem;
  font-weight: 800; }

h2 {
  font-size: 1.8rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    h2 {
      font-size: 2.2rem; } }

h3 {
  font-size: 1.5rem;
  font-weight: 600; }
  @media (min-width: 992px) {
    h3 {
      font-size: 2rem; } }

h4 {
  font-size: 1.2rem;
  font-weight: 500; }
  @media (min-width: 992px) {
    h4 {
      font-size: 1.8rem; } }

h5 {
  font-size: 1.125rem;
  font-weight: 500; }

h6 {
  font-size: 1rem; }

p {
  font-size: 1rem; }

.small-font {
  font-size: 0.813rem; }

.large-font {
  font-size: 2.5rem; }

.xl-large-font {
  font-size: 4.625rem; }

.metro-top-gradient {
  background: linear-gradient(90deg, var(--top-gradient-color-1) 35%, var(--top-gradient-color-2) 80%); }

.metro-text-dark-color {
  color: #2C2C2C; }

.metro-title-color {
  color: #2C2C2C; }

.metro-primary-color, .read_more {
  color: var(--primary-hover); }

.read_more:hover {
  color: var(--primary-hover); }

.metro-footer-bg {
  background-color: #1d1d1d; }

.line-height {
  line-height: 1.5rem; }

.entry-content a {
  color: var(--primary-hover); }
  .entry-content a:hover {
    color: var(--primary-color); }

.entry-content p {
  font-size: 1rem;
  line-height: 1.5rem; }

.entry-content h2 {
  color: #000 !important;
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  margin: 2rem 0 1.3rem 0 !important; }
  @media (min-width: 992px) {
    .entry-content h2 {
      font-size: 2.2rem; } }

.entry-content h3 {
  color: #2C2C2C;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1.3rem 0; }
  @media (min-width: 992px) {
    .entry-content h3 {
      font-size: 2rem; } }

.entry-content h4, .entry-content h5 {
  color: #000 !important;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 1.4rem 0 1.3rem 0; }
  @media (min-width: 992px) {
    .entry-content h4, .entry-content h5 {
      font-size: 1.8rem; } }

.entry-content ul li {
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 1rem; }

.service-bg {
  background-color: #E8E8E8;
  margin-top: 2rem;
  margin-bottom: 1rem; }
  @media (min-width: 992px) {
    .service-bg {
      margin: 4rem 0; } }
  .service-bg .card-title {
    color: black; }
  .service-bg h3 {
    margin: 0; }

.google-banner {
  padding-top: 2rem;
  padding-bottom: 1rem; }

.license-number {
  padding-bottom: 3rem;
  color: #2C2C2C; }
  @media (min-width: 992px) {
    .license-number {
      padding-bottom: 0; } }
  .license-number h4 {
    color: #000 !important;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 1.4rem 0 1.3rem 0; }
    @media (min-width: 992px) {
      .license-number h4 {
        font-size: 1.7rem;
        font-weight: 700; } }
  .license-number strong {
    font-size: 1rem;
    padding-right: 1rem;
    color: #626262;
    line-height: 1.8; }

.margin-top {
  margin-top: 8rem; }

.alert {
  background-color: #1C1C1C;
  font-size: .875rem; }
  .alert span, .alert a {
    color: var(--primary-color); }

.close {
  font-size: 2.5rem;
  line-height: 0.7;
  color: #FFF !important;
  font-weight: 300;
  cursor: pointer;
  background: unset;
  border: unset; }
  .close span {
    color: #FFF !important; }

.alert a:hover {
  color: unset; }

.close:hover {
  text-decoration: none; }

.location-main .home-service {
  padding: 2rem 0 5rem 0; }
  .location-main .home-service h5 {
    color: #212529; }
  .location-main .home-service .card-title-desc {
    padding: 2rem 0 0 0; }

.top-gradient {
  height: 5px;
  background-color: var(--tertiary-color);
  background-image: linear-gradient(90deg, var(--top-gradient-color-1) 35%, var(--top-gradient-color-2) 100%); }

.header-service {
  letter-spacing: 0.03rem; }

.top-nav {
  background-color: #2C2C2C;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.3125rem 0;
  line-height: 2.7; }
  @media (min-width: 992px) {
    .top-nav {
      padding: 0; } }
  @media (min-width: 420px) {
    .top-nav {
      font-size: 0.625rem; } }
  @media (min-width: 1200px) {
    .top-nav {
      font-size: 0.75rem; } }
  .top-nav .metro-front {
    font-weight: bold; }
  .top-nav a:hover {
    color: #CCC; }
  .top-nav ul {
    margin: 0;
    padding: 0;
    /* font-weight: 600;*/ }
    .top-nav ul li {
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      /*line-height: 1.4;*/ }
      .top-nav ul li:hover {
        text-decoration: underline; }
      .top-nav ul li:after {
        content: "";
        height: 1rem;
        display: inline-block;
        position: absolute;
        top: 13px;
        right: auto;
        border-right: 2px solid #626262; }
      .top-nav ul li:last-child:after {
        border-right: none; }
      .top-nav ul li a {
        text-decoration: none;
        outline: none;
        color: #B7C2C7;
        text-transform: capitalize;
        padding: 0.3125rem 0.625rem;
        letter-spacing: 0.03rem; }
        .top-nav ul li a:hover {
          text-decoration: none;
          color: #EEE; }
  .top-nav #metropolitan-menu .active {
    background-color: var(--tertiary-hover); }
  .top-nav #metropolitan-menu .right-bar:after {
    content: "";
    height: 1rem;
    display: inline-block;
    position: absolute;
    top: 13px;
    right: auto;
    border-right: 2px solid #3D4144; }
  .top-nav .active a {
    color: white; }
  .top-nav .click-disable {
    pointer-events: none; }
  .top-nav .nav-bookcall {
    line-height: 1; }
    .top-nav .nav-bookcall .hours {
      height: 40px;
      width: auto;
      vertical-align: middle; }
      @media (min-width: 576px) {
        .top-nav .nav-bookcall .hours {
          height: 40px; } }
    .top-nav .nav-bookcall .nav-phone {
      font-size: 1rem;
      padding-left: 0.3rem;
      font-weight: 500;
      vertical-align: middle; }
      @media (min-width: 376px) {
        .top-nav .nav-bookcall .nav-phone {
          font-size: 1.5rem;
          padding-left: 0.25rem;
          font-weight: 700; } }
      @media (min-width: 576px) {
        .top-nav .nav-bookcall .nav-phone {
          font-size: 1.8rem;
          padding-left: 0.8rem; } }
    @media (max-width: 576px) {
      .top-nav .nav-bookcall .btn-small {
        position: absolute;
        top: -5px;
        right: 0;
        height: 50px;
        border-radius: 0;
        width: 150px;
        padding-top: 0.75rem; } }
  .top-nav .hours {
    max-width: 30px; }
  .top-nav .nav-phone {
    color: white;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    vertical-align: middle; }

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(46, 49, 146, 1)' stroke-width='3' stroke-linecap='0' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  width: 2em !important;
  height: 3em !important; }

.navbar-toggler {
  outline: none; }

.navbar-m-dark {
  background: #E8E8E8 !important; }

.navbar {
  padding: 0.75rem 0;
  background: #FFFFFF;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.1); }
  @media (min-width: 430px) {
    .navbar a {
      font-size: 0.75rem; } }
  @media (min-width: 1200px) {
    .navbar a {
      font-size: 0.875rem; } }
  .navbar .container {
    padding-right: 15px;
    padding-left: 15px; }
  .navbar .custom-logo-link img {
    height: auto;
    width: 180px; }
    @media (min-width: 430px) {
      .navbar .custom-logo-link img {
        width: 200px; } }
  .navbar .nav-link {
    color: #626262 !important; }
    @media (min-width: 992px) {
      .navbar .nav-link {
        padding: 0 1.5rem !important; } }
    .navbar .nav-link:hover {
      color: var(--primary-color) !important; }
  .navbar .product-review {
    width: 30%;
    padding: 0 2rem; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
  background: #FFFFFF;
  border: none;
  margin: 0;
  border-radius: 0; }
  @media (min-width: 992px) {
    .navbar-nav .dropdown-menu {
      padding: 2.2rem 0.5rem 1rem;
      box-shadow: 0 0.5rem 0.25rem -0.25rem rgba(0, 0, 0, 0.1); } }

.navbar-nav li:hover .dropdown-menu {
  display: block; }

.navbar-nav a {
  font-weight: 600; }

.dropdown-item {
  color: #2C2C2C;
  padding: .25rem 1rem; }
  .dropdown-item:focus, .dropdown-item:hover, .dropdown-item:active {
    color: var(--primary-color);
    text-decoration: none;
    background-color: #FFFFFF;
    transition: ease-in 0.3s; }

.navbar-border {
  height: auto;
  background-color: #2C2C2C;
  color: #FFFFFF;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem;
  padding-bottom: 0.7rem; }
  .navbar-border span a {
    color: #01AEFF; }

.dropdown-toggle:after {
  content: none; }

.nav-bookcall .nav-phone {
  color: #2A3548;
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0;
  line-height: 0;
  vertical-align: middle; }
  @media (min-width: 420px) {
    .nav-bookcall .nav-phone {
      font-size: 1.125rem; } }
  @media (min-width: 768px) {
    .nav-bookcall .nav-phone {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .nav-bookcall .nav-phone {
      line-height: 2.7;
      font-size: 1.5rem;
      margin-right: 1rem;
      font-weight: bolder; } }

.dropdown-item, .custom-dropdown-item {
  font-weight: 500 !important; }
  @media (min-width: 576px) {
    .dropdown-item, .custom-dropdown-item {
      color: #323232;
      padding: .25rem 1rem; } }
  @media (min-width: 992px) {
    .dropdown-item, .custom-dropdown-item {
      color: #636363;
      text-align: left; } }
  .dropdown-item:hover, .custom-dropdown-item:hover {
    color: var(--primary-color);
    text-decoration: none; }

.findplumb-menu {
  margin: 0 -8px -16px;
  padding: .5rem; }
  @media (max-width: 576px) {
    .findplumb-menu {
      margin: 10px 0 0 -.25rem;
      padding: .625rem 0 0; } }
  .findplumb-menu a .fal:after {
    height: 1.5rem;
    width: 1.5rem; }
  .findplumb-menu a:active, .findplumb-menu a:focus, .findplumb-menu a:hover {
    color: var(--primary-color);
    background: 0 0; }

#main-menu {
  display: none; }
  @media (min-width: 992px) {
    #main-menu {
      display: inline-flex; } }
  #main-menu .dropdown-menu .dropdown-menu {
    left: 100%;
    top: -0.5rem;
    padding: 0.5rem 0;
    display: none !important; }
  #main-menu .dropdown-menu .dropdown > .custom-dropdown-item:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #626262;
    float: right;
    margin-top: 10px;
    transition: transform 0.25s; }
  #main-menu .dropdown:hover > .dropdown-menu {
    display: block !important; }
  #main-menu .dropdown:hover > .custom-dropdown-item:after {
    border-top: 5px solid var(--primary-color);
    transform: rotate(-90deg); }

@media (min-width: 992px) {
  #mobile-menu {
    display: none; } }

/*Sidepanel CSS*/
.screen-overlay {
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity .2s linear, visibility .1s, width 1s ease-in; }
  .screen-overlay.show {
    transition: opacity .5s ease, width 0s;
    opacity: 1;
    width: 100%;
    visibility: visible; }

.offcanvas {
  min-width: 320px;
  width: 90%;
  visibility: hidden;
  transform: translateX(100%);
  transition: all .2s;
  border-radius: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1200;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #26395A; }
  @media (min-width: 576px) {
    .offcanvas {
      width: 420px; } }
  .offcanvas.show {
    visibility: visible;
    transform: translateX(0);
    transition: transform .2s; }

.btn-close {
  display: block;
  position: fixed;
  z-index: 33;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  transition: all 1s ease; }
  .btn-close:hover {
    transform: scale(1.1); }
  .btn-close .bar {
    width: 35px;
    height: 3px;
    border-radius: 3px;
    margin: 11px auto;
    background-color: #FFFFFF; }
    .btn-close .bar:first-child {
      transform: translateY(7px) rotate(45deg); }
    .btn-close .bar:last-child {
      transform: translateY(-7px) rotate(-45deg); }

#mobile-menu {
  line-height: 1; }
  #mobile-menu .menu-item a {
    color: #FFFFFF;
    font-size: 1.5rem;
    padding: 0.75rem 1rem; }
  #mobile-menu li ul {
    list-style: none;
    background: transparent;
    color: white;
    position: relative !important;
    transform: none !important;
    padding: 0;
    border-radius: unset;
    border: none; }
    #mobile-menu li ul li {
      display: block; }
      #mobile-menu li ul li a {
        font-size: 1rem !important;
        color: #fff !important;
        padding: 0.5rem 1rem !important;
        display: inline-block; }
        #mobile-menu li ul li a:hover, #mobile-menu li ul li a:focus {
          background-color: transparent !important; }
      #mobile-menu li ul li ul li a {
        margin-left: 1.5rem !important;
        padding: 0.25rem 0;
        font-size: 1rem !important; }
  #mobile-menu li .dropdown-toggle:after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #FFFFFF; }
  #mobile-menu .dropdown-menu {
    padding: 0.5rem 0 0.25rem 1rem !important;
    margin-top: -0.5rem;
    float: none; }
    #mobile-menu .dropdown-menu li.dropdown a:before {
      content: "+";
      margin-right: 5px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 1.2rem; }
    #mobile-menu .dropdown-menu .dropdown-menu {
      padding: 0 0 0.25rem !important;
      margin-top: 0;
      float: unset; }
      #mobile-menu .dropdown-menu .dropdown-menu li {
        width: 100%; }
        #mobile-menu .dropdown-menu .dropdown-menu li a:before {
          content: ""; }
  #mobile-menu .menu-item-has-children {
    position: relative; }
  #mobile-menu .menu-item-has-children a::after {
    transition: all 0.25s;
    vertical-align: 2px; }
  #mobile-menu .menu-item-has-children a[aria-expanded="true"]::after {
    transform: rotate(90deg);
    vertical-align: -2px; }
  #mobile-menu .menu-item-has-children .sub-menu {
    top: 0;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem; }

@media (max-width: 992px) {
  .main-footer .footer-logo img {
    max-width: 225px; } }

.main-footer .social-icons a {
  text-decoration: none; }
  .main-footer .social-icons a:hover {
    filter: brightness(200%); }

.main-footer .footer-right-main {
  /*@media(min-width: 1200px) {
			padding-left: 5rem;
		}*/ }
  .main-footer .footer-right-main .footer-menus .widget_nav_menu {
    margin-bottom: 3rem; }
  .main-footer .footer-right-main .footer-menus .widget-title {
    color: #FFFFFF;
    padding-bottom: 1rem !important;
    font-weight: 600; }
  .main-footer .footer-right-main .footer-menus ul {
    padding: 0; }
    .main-footer .footer-right-main .footer-menus ul li {
      list-style: none;
      margin: 0 0 .4rem; }
      .main-footer .footer-right-main .footer-menus ul li a {
        color: #DDDDDD;
        text-decoration: none;
        font-weight: 400;
        font-size: 1rem; }
        .main-footer .footer-right-main .footer-menus ul li a:hover {
          color: #FFFFFF; }

.metro-footer-bg-up .subject {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }
  @media (min-width: 992px) {
    .metro-footer-bg-up .subject {
      margin-bottom: 0; } }

.message-wrapper {
  position: absolute;
  bottom: 6rem;
  background-color: #3D4144;
  color: #FFF;
  font-weight: 600;
  border-radius: 1rem;
  width: 50%; }
  @media (min-width: 450px) {
    .message-wrapper {
      bottom: 8rem; } }
  @media (min-width: 576px) {
    .message-wrapper {
      bottom: 9rem;
      width: 60%; } }
  @media (min-width: 768px) {
    .message-wrapper {
      width: 70%; } }
  @media (min-width: 992px) {
    .message-wrapper {
      bottom: 9.5rem;
      right: 136px;
      width: 28%; } }
  @media (min-width: 1200px) {
    .message-wrapper {
      width: 31%; } }
  @media (max-width: 991px) {
    .message-wrapper {
      right: 12rem; } }
  @media (max-width: 575px) {
    .message-wrapper {
      right: 10rem; } }
  .message-wrapper .message-box {
    position: relative;
    padding: 1rem; }
    @media (min-width: 992px) {
      .message-wrapper .message-box {
        padding: 1.5rem 1.5rem 1.5rem 1rem;
        text-align: left; } }
    @media (min-width: 1200px) {
      .message-wrapper .message-box {
        padding: 1.5rem 3rem 1.5rem 1rem; } }
    .message-wrapper .message-box .message-text:after {
      position: absolute;
      content: "";
      bottom: -40px;
      right: 5rem;
      border-top: 40px solid #3D4144;
      border-left: 35px solid transparent; }
    .message-wrapper .message-box .message-text p {
      margin: 0; }

.message-girl {
  float: right; }
  @media (min-width: 992px) {
    .message-girl {
      position: absolute;
      bottom: 0;
      right: -1rem; } }
  @media (min-width: 1200px) {
    .message-girl {
      right: 0; } }
  @media (max-width: 575px) {
    .message-girl {
      margin-right: -2rem; } }

.footer-payicons {
  max-height: 25px;
  height: auto;
  width: auto; }

@media (max-width: 767px) {
  .footer-find-near .fa-map-marker-alt {
    display: block; } }

.footer-find-near .fa-map-marker-alt:after {
  height: 2.25rem;
  width: 2.25rem; }
  @media (min-width: 768px) {
    .footer-find-near .fa-map-marker-alt:after {
      position: absolute;
      margin-top: 0.25rem; } }

.footer-find-near a {
  text-decoration: none;
  color: #FFFFFF; }

.footer-find-near .footer-find-near-main {
  line-height: 1.4rem; }
  .footer-find-near .footer-find-near-main img {
    margin: 0 auto;
    max-width: 1.25rem; }
    @media (min-width: 768px) {
      .footer-find-near .footer-find-near-main img {
        float: left;
        margin: 0.375rem 0.625rem 1rem auto; } }

.footer-find-near span {
  font-weight: 600;
  display: inline-block; }
  @media (min-width: 768px) {
    .footer-find-near span {
      display: block !important; } }

.social-icons a:hover {
  text-decoration: none; }

.license-number-footer {
  color: #808080;
  text-align: center; }
  @media (min-width: 992px) {
    .license-number-footer {
      text-align: left; } }
  .license-number-footer strong {
    font-size: 0.75rem;
    color: #979797;
    line-height: 1.8; }
  .license-number-footer ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .license-number-footer li {
    display: block; }
    @media (min-width: 576px) {
      .license-number-footer li {
        display: inline-block; } }
  .license-number-footer span {
    display: none;
    padding: 0 .5rem; }
    @media (min-width: 576px) {
      .license-number-footer span {
        display: inline-block; } }

.home-hero {
  height: auto;
  position: relative;
  color: #FFFFFF;
  /*background: linear-gradient(-45deg, #61045F, #148C66, #C3007A, #0F52CF);*/
  background: linear-gradient(135deg, var(--home-hero-color-1), var(--home-hero-color-2), var(--home-hero-color-3), var(--home-hero-color-4));
  background-size: 400% 200%;
  animation: gradient 15s ease infinite; }
  .home-hero .container {
    padding: 15px 0; }
    @media (min-width: 420px) {
      .home-hero .container {
        padding: 30px 0; } }
    @media (min-width: 576px) {
      .home-hero .container {
        padding: 45px 0; } }
    @media (min-width: 768px) {
      .home-hero .container {
        padding: 60px 0; } }
    .home-hero .container .no-gutters {
      padding: 2rem; }
      @media (min-width: 576px) {
        .home-hero .container .no-gutters {
          padding: 0; } }
    .home-hero .container .hero-text .btn-success {
      padding: 0.60rem 5rem;
      font-size: 1rem;
      border-radius: 0.3rem;
      background-color: var(--button-color) !important;
      box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.25);
      border: none;
      transition: none; }
      @media (min-width: 1200px) {
        .home-hero .container .hero-text .btn-success {
          padding: 0.60rem 8rem; } }
      .home-hero .container .hero-text .btn-success:hover, .home-hero .container .hero-text .btn-success:focus, .home-hero .container .hero-text .btn-success:active {
        background-color: var(--button-hover-color) !important;
        border: none;
        box-shadow: 0 1px 4px 4px rgba(0, 0, 0, 0.25); }
    .home-hero .container h1 {
      font-weight: 600;
      margin: 1rem 0;
      font-size: 1.5rem; }
      @media (min-width: 768px) {
        .home-hero .container h1 {
          font-size: 1.75rem;
          margin: 1rem 0; } }
      @media (min-width: 992px) {
        .home-hero .container h1 {
          font-size: 2.5rem; } }
      .home-hero .container h1 a {
        color: #FFFFFF;
        font-weight: 700;
        font-size: 2.25rem; }
        @media (min-width: 768px) {
          .home-hero .container h1 a {
            font-size: 5rem; } }
    .home-hero .container h2 {
      margin: 1rem 0; }
      .home-hero .container h2 a {
        color: #FFF;
        font-weight: 300;
        font-size: 3rem;
        text-decoration: none; }
        .home-hero .container h2 a:hover, .home-hero .container h2 a:focus {
          color: #FFF;
          text-shadow: 0 2px 2px #1C2739;
          transition: ease-in .3s; }
        @media (min-width: 992px) {
          .home-hero .container h2 a {
            font-size: 4rem; } }
        @media (min-width: 1200px) {
          .home-hero .container h2 a {
            font-size: 4.5rem; } }
    .home-hero .container h3 {
      font-size: 1.75rem;
      margin: 0;
      font-weight: 400; }
      @media (min-width: 768px) {
        .home-hero .container h3 {
          font-size: 2.625rem; } }
    .home-hero .container p {
      margin: 0.9375rem 0 0 0; }
    .home-hero .container small {
      font-size: 1rem;
      font-weight: 400; }

#homeCarousel .carousel-item {
  min-height: 250px;
  background-color: var(--tertiary-color); }

#homeCarousel .container {
  position: relative; }

#homeCarousel .hero-badge, #homeCarousel .hero-human {
  position: absolute; }

#homeCarousel .hero-human {
  bottom: 0;
  left: 0; }
  @media (min-width: 1200px) {
    #homeCarousel .hero-human {
      left: 100px; } }
  #homeCarousel .hero-human img {
    height: 350px;
    width: auto; }
    @media (min-width: 1200px) {
      #homeCarousel .hero-human img {
        height: 30vw;
        max-height: 500px; } }

#homeCarousel .hero-badge img {
  height: 130px;
  width: auto;
  position: relative;
  z-index: 20;
  top: -25px;
  right: 45px; }

#homeCarousel .cta-box {
  position: absolute;
  top: -380px;
  right: 15px;
  padding: 1.875rem 1.875rem 1.875rem 3.75rem;
  width: auto;
  min-width: 550px; }
  @media (min-width: 1200px) {
    #homeCarousel .cta-box {
      right: 100px;
      top: -420px; } }
  #homeCarousel .cta-box h5 {
    margin-bottom: 1.1875rem; }
  #homeCarousel .cta-box h1 {
    font-size: 2.25rem;
    font-weight: 700;
    white-space: nowrap; }
  #homeCarousel .cta-box h5 .text-yellow {
    font-size: 3.125rem;
    font-weight: 700; }
  #homeCarousel .cta-box .btn-book {
    padding: 0.9375rem 7.5rem; }
    #homeCarousel .cta-box .btn-book .text-yellow {
      font-size: 1.75rem;
      font-weight: 700; }

@media (min-width: 768px) and (max-width: 991px) {
  /* Show 4th slide on md if col-md-4*/
  .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -33.3333%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible; } }

@media (min-width: 576px) and (max-width: 768px) {
  /* Show 3rd slide on sm if col-sm-6*/
  .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -50%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible; } }

@media (min-width: 576px) {
  .carousel-item {
    margin-right: 0; }
  /* show 2 items */
  .carousel-inner .active + .carousel-item {
    display: block; }
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none; }
  .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0); }
  /* left or forward direction */
  .active.carousel-item-left + .carousel-item-next.carousel-item-left,
  .carousel-item-next.carousel-item-left + .carousel-item,
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  /* farthest right hidden item must be also positioned for animations */
  .carousel-inner .carousel-item-prev.carousel-item-right {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: block;
    visibility: visible; }
  /* right or prev direction */
  .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
  .carousel-item-prev.carousel-item-right + .carousel-item,
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible; } }

/* MD */
@media (min-width: 768px) {
  /* show 3rd of 3 item slide */
  .carousel-inner .active + .carousel-item + .carousel-item {
    display: block; }
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none; }
  .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0); }
  /* left or forward direction */
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  /* right or prev direction */
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible; } }

/* LG */
@media (min-width: 991px) {
  /* show 4th item */
  .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block; }
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none; }
  /* Show 5th slide on lg if col-lg-3 */
  .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: absolute;
    top: 0;
    right: -25%;
    /*change this with javascript in the future*/
    z-index: -1;
    display: block;
    visibility: visible; }
  /* left or forward direction */
  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  /* right or prev direction //t - previous slide direction last item animation fix */
  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    position: relative;
    transform: translate3d(100%, 0, 0);
    visibility: visible;
    display: block;
    visibility: visible; } }

#brands-carousel {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  #brands-carousel .brand-images {
    height: auto;
    width: 100%;
    margin: 15px; }
    @media (min-width: 420px) {
      #brands-carousel .brand-images {
        height: 90px;
        width: auto; } }
    @media (min-width: 992px) {
      #brands-carousel .brand-images {
        height: 115px;
        width: auto; } }
  #brands-carousel .carousel-item {
    border-radius: 0.25rem; }
    #brands-carousel .carousel-item:hover {
      background-color: #2C2C2C; }
  #brands-carousel .brands-intro {
    height: 100px;
    width: 100%;
    overflow: hidden;
    padding: 0.25rem 0.5rem;
    color: #6c757d;
    border-radius: 0.25rem;
    text-align: center; }
    @media (min-width: 992px) {
      #brands-carousel .brands-intro {
        padding: 0.5rem 1rem; } }
    #brands-carousel .brands-intro p {
      margin: 0;
      font-size: 1rem;
      line-height: 1.2; }
  #brands-carousel .carousel-control-next,
  #brands-carousel .carousel-control-prev {
    top: calc(50% - 0.75rem);
    bottom: calc(50% - 0.75rem);
    width: 2rem;
    opacity: 0.5;
    transition: all 1s ease; }
    @media (min-width: 576px) {
      #brands-carousel .carousel-control-next,
      #brands-carousel .carousel-control-prev {
        width: 3rem; } }
    #brands-carousel .carousel-control-next:hover,
    #brands-carousel .carousel-control-prev:hover {
      opacity: 0.66;
      transform: scale(1.1); }
  #brands-carousel .carousel-control-next {
    right: 0; }
    @media (min-width: 576px) {
      #brands-carousel .carousel-control-next {
        right: -2rem; } }
  #brands-carousel .carousel-control-prev {
    left: 0; }
    @media (min-width: 576px) {
      #brands-carousel .carousel-control-prev {
        left: -2rem; } }
  #brands-carousel .carousel-control-next-icon,
  #brands-carousel .carousel-control-prev-icon {
    height: 2rem;
    width: 2rem;
    content: "";
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: #2C2C2C;
    display: inline-block;
    background-image: none; }
  #brands-carousel .carousel-control-next-icon {
    transform: rotate(45deg); }
  #brands-carousel .carousel-control-prev-icon {
    transform: rotate(-135deg); }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }

.glide * {
  box-sizing: inherit; }

.glide__track {
  overflow: hidden; }

.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform; }

.glide__slides--dragging {
  user-select: none; }

.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none; }

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none; }

.glide--rtl {
  direction: rtl; }

.glide {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .glide__slide {
    text-align: center;
    position: relative; }
    @media (max-width: 575px) {
      .glide__slide {
        /*background-color: $light-color;
			opacity: 0.25;
			&--active {
					background-color: transparent;
					opacity: 1;
			}*/ } }
    @media (min-width: 576px) {
      .glide__slide {
        border-radius: 0.25rem; }
        .glide__slide:hover .brands-intro {
          opacity: 0.95; } }
    .glide__slide .brands-intro {
      position: absolute;
      opacity: 0;
      background-color: #3D4144;
      color: #FFFFFF;
      border-radius: 0.25rem;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      padding: 5px;
      line-height: 1.2; }
      .glide__slide .brands-intro p {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.2; }
    .glide__slide .brands-intro-sm {
      height: 125px;
      overflow: hidden;
      text-align: center;
      color: #3D4144;
      padding: 0 5px; }
    .glide__slide img {
      height: 90px;
      width: auto; }
      @media (min-width: 420px) {
        .glide__slide img {
          height: 120px; } }
      @media (min-width: 768px) {
        .glide__slide img {
          height: 90px; } }
      @media (min-width: 992px) {
        .glide__slide img {
          height: 120px; } }
  .glide__arrow {
    position: absolute;
    top: calc(50% - 0.75rem);
    bottom: calc(50% - 0.75rem);
    width: 1rem !important;
    opacity: 0.5;
    transition: all 1s ease;
    cursor: pointer; }
    @media (min-width: 576px) {
      .glide__arrow {
        width: 3rem; } }
    .glide__arrow:hover {
      opacity: 0.66;
      transform: scale(1.1); }
    @media (max-width: 575px) {
      .glide__arrow {
        display: none; } }
    .glide__arrow:after {
      height: 1rem;
      width: 1rem;
      content: "";
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: #2C2C2C;
      display: inline-block; }
    .glide__arrow--left {
      left: 0; }
      @media (min-width: 576px) {
        .glide__arrow--left {
          left: -1rem; } }
      .glide__arrow--left:after {
        transform: rotate(-135deg); }
    .glide__arrow--right {
      right: 0; }
      @media (min-width: 576px) {
        .glide__arrow--right {
          right: -1rem; } }
      .glide__arrow--right:after {
        transform: rotate(45deg); }

.other-hero {
  height: auto;
  position: relative;
  color: #FFFFFF;
  /*background: linear-gradient(-45deg, #61045F, #148C66, #C3007A, #0F52CF);*/
  background: linear-gradient(135deg, var(--home-hero-color-1), var(--home-hero-color-2), var(--home-hero-color-3), var(--home-hero-color-4));
  background-size: 400% 200%;
  animation: gradient 15s ease infinite; }
  .other-hero .no-gutters {
    padding: 2rem 2rem 0.5rem 2rem; }
    @media (min-width: 992px) {
      .other-hero .no-gutters {
        padding: 2rem 0; } }
    .other-hero .no-gutters .other-right {
      padding: 1rem 0;
      text-align: center; }
      @media (min-width: 992px) {
        .other-hero .no-gutters .other-right {
          padding: 3rem 0 2rem 3rem;
          text-align: left; } }
      .other-hero .no-gutters .other-right .h1-first {
        color: #FFF;
        font-size: 2rem;
        font-weight: bold; }
        @media (min-width: 575px) {
          .other-hero .no-gutters .other-right .h1-first {
            font-weight: 600;
            font-size: 2rem; } }
        @media (min-width: 767px) {
          .other-hero .no-gutters .other-right .h1-first {
            font-weight: 600;
            font-size: 2rem; } }
        @media (min-width: 992px) {
          .other-hero .no-gutters .other-right .h1-first {
            font-weight: bold;
            font-size: 2.5rem; } }
      .other-hero .no-gutters .other-right .h1-second {
        display: block !important;
        font-size: 3rem;
        color: #FFF;
        font-weight: bold; }
        @media (min-width: 992px) {
          .other-hero .no-gutters .other-right .h1-second {
            font-size: 4.5rem; } }

.second-other-part {
  background-color: var(--secondary-hover);
  padding: 1.5rem 0 2rem 0; }
  @media (min-width: 992px) {
    .second-other-part {
      padding: 1.5rem 0; } }
  .second-other-part h4 {
    display: inline;
    color: white;
    font-style: italic;
    font-size: 2rem;
    text-shadow: 2px 2px #000000;
    vertical-align: middle;
    line-height: 1.5; }
    @media (min-width: 992px) {
      .second-other-part h4 {
        padding-bottom: 0;
        font-size: 2.6rem; } }
    .second-other-part h4 span {
      color: var(--primary-hover);
      font-weight: bold; }
  .second-other-part .book-now-text {
    display: inline;
    color: white;
    font-style: italic;
    font-size: 2rem;
    text-shadow: 2px 2px #000000;
    vertical-align: middle;
    line-height: 1.5; }
    @media (min-width: 996px) {
      .second-other-part .book-now-text {
        padding-bottom: 0;
        font-size: 2.6rem; } }
    .second-other-part .book-now-text span {
      color: var(--primary-hover);
      font-weight: bold; }
  .second-other-part .other-btn .btn {
    padding: 0.8rem 5rem;
    border-radius: 10px;
    border: 8px solid #1D2E4C;
    margin-top: 1.5rem;
    font-size: 18px; }
    @media (min-width: 1200px) {
      .second-other-part .other-btn .btn {
        padding: 0.5rem 4rem;
        margin-top: 0; } }
    .second-other-part .other-btn .btn:hover, .second-other-part .other-btn .btn:focus, .second-other-part .other-btn .btn:active {
      background-color: var(--primary-hover) !important;
      box-shadow: none !important;
      border: 8px solid #1D2E4C !important; }

.blog-sidebar {
  background-color: #E8E8E8;
  padding: 1.5rem 2rem;
  border-radius: .75rem; }
  .blog-sidebar h4 {
    font-weight: 600; }

.blog-left {
  background: #E8E8E8;
  padding: 10px 28px;
  border-radius: .75rem; }
  .blog-left h3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700; }
  .blog-left ul {
    margin: 0;
    padding: 0;
    overflow: hidden; }
    .blog-left ul li {
      overflow: hidden;
      font-size: 13px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #DDD;
      padding-bottom: 20px; }
      .blog-left ul li:last-child {
        border: none; }
  .blog-left .post-categories {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline; }
    .blog-left .post-categories li {
      margin: 0;
      padding: 0;
      display: inline; }
  .blog-left .sidebar-thumb {
    float: left;
    overflow: hidden;
    margin-right: 15px; }
    .blog-left .sidebar-thumb img {
      height: auto;
      width: 75px;
      margin: 0.5rem 0 2rem;
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      border-radius: 0.5rem; }
  .blog-left .sidebar-content h5 {
    font-size: 16px;
    cursor: pointer;
    line-height: 24px; }
  .blog-left .sidebar-content a {
    color: #858585;
    outline: 0 none;
    text-decoration: none;
    font-weight: bold;
    list-style: none; }
  .blog-left .sidebar-content a:hover {
    color: #2996BD; }
  .blog-left .sidebar-meta span {
    color: #2E2E2E; }
  .blog-left .sidebar-meta .time {
    margin-right: 10px; }
  .blog-left .sidebar-meta .author {
    color: #407BDF; }

.btn {
  color: #FFFFFF;
  border-radius: 0.25rem;
  border: 2px solid #FFFFFF;
  background-color: transparent;
  transition: background-color 1s ease;
  padding: 5px 10px; }
  @media (min-width: 768px) {
    .btn {
      padding: 10px 20px; } }
  @media (min-width: 992px) {
    .btn {
      padding: 10px 12px; } }
  @media (min-width: 1200px) {
    .btn {
      padding: 15px 30px; } }
  .btn:hover {
    background-color: var(--tertiary-color);
    color: #FFFFFF; }

.btn-success {
  border: 2px solid var(--button-color);
  background-color: var(--button-color);
  font-size: 1rem;
  font-weight: 700;
  padding: 1.25rem 4rem;
  text-transform: capitalize; }
  .btn-success:hover {
    background-color: var(--button-hover-color); }

.btn-primary {
  border: 2px solid var(--button-color);
  background-color: var(--button-color);
  font-size: 1.3rem;
  font-weight: 700; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: var(--button-hover-color) !important;
    box-shadow: none !important;
    border-color: var(--button-hover-color) !important; }

.hero-text .btn-success {
  border: 2px solid var(--button-color);
  background-color: var(--button-color);
  font-size: 1rem;
  font-weight: 700;
  padding: 1.25rem 4rem; }
  .hero-text .btn-success:hover {
    background-color: var(--button-hover-color); }

.btn-brands {
  padding: 0;
  border: none !important;
  background-color: #FFFFFF;
  color: #555;
  border-radius: 0; }
  .btn-brands:hover, .btn-brands:active, .btn-brands:focus {
    box-shadow: none;
    border: 0;
    background-color: #FFFFFF !important;
    padding: 0;
    color: #3A5DB3 !important; }

.btn-book {
  padding: 15px 30px;
  background-color: #FB3048;
  display: inline-block;
  line-height: 1.2;
  text-align: center;
  border-radius: 3px;
  position: relative; }
  @media (min-width: 768px) {
    .btn-book {
      padding: 15px 60px; } }
  .btn-book span:first-child {
    font-weight: 600;
    font-size: 12px; }
    @media (min-width: 768px) {
      .btn-book span:first-child {
        font-size: 18px; } }
  .btn-book span:last-child {
    font-size: 10px; }
    @media (min-width: 768px) {
      .btn-book span:last-child {
        font-size: 12px; } }
  .btn-book .btn-arrow {
    position: absolute;
    height: 60px;
    width: auto;
    top: -15px;
    right: -30px; }
    @media (min-width: 992px) {
      .btn-book .btn-arrow {
        height: 75px; } }

.cta-box {
  border-radius: 2px;
  background-image: linear-gradient(to bottom right, #09275D 33%, #365792 50%, #09275D 66%);
  color: #FFFFFF;
  width: max-content;
  padding: 15px; }
  @media (min-width: 576px) {
    .cta-box {
      padding: 30px; } }
  @media (min-width: 768px) {
    .cta-box {
      padding: 45px; } }
  @media (min-width: 992px) {
    .cta-box {
      padding: 60px; } }
  .cta-box h5 {
    font-size: 26px; }
  .cta-box h1 {
    font-size: 42px; }

.btn-small {
  padding: 7px 18px;
  font-size: 1rem; }

.nav-bookcall .btn-success {
  border: 2px solid var(--button-color);
  background-color: var(--button-color);
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5rem 0.9rem;
  vertical-align: middle; }
  @media (min-width: 576px) and (max-width: 992px) {
    .nav-bookcall .btn-success {
      margin-top: -5px;
      margin-bottom: 5px;
      border-radius: 0 0 0.3rem 0.3rem; } }
  @media (min-width: 768px) {
    .nav-bookcall .btn-success {
      padding: 0.5rem 0.75rem; } }
  @media (min-width: 1200px) {
    .nav-bookcall .btn-success {
      padding: 0.625rem 2.5rem; } }
  .nav-bookcall .btn-success:hover, .nav-bookcall .btn-success:focus, .nav-bookcall .btn-success:active {
    background-color: var(--button-hover-color) !important;
    box-shadow: none !important;
    border-color: var(--button-hover-color) !important;
    color: #fff; }

.btn-more {
  border: 2px solid var(--button-color);
  color: white;
  font-weight: 600;
  padding: 0.85rem 2rem;
  background-color: var(--button-color);
  border-radius: 0.3rem; }
  .btn-more:focus {
    box-shadow: none; }
  .btn-more:hover {
    border: 2px solid var(--button-hover-color);
    background-color: var(--button-hover-color); }

.fa-2x:after {
  transform: scale(1.1); }

.fa:after,
.fab:after,
.fal:after {
  /*
	background-repeat:   no-repeat;
	background-size:     contain;
	background-position: top center;*/
  display: inline-block;
  height: 2rem;
  width: 2rem;
  content: " ";
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: top left;
  background-color: var(--map-color);
  vertical-align: middle; }

.fa-facebook-f:after,
.fa-facebook:after {
  mask-image: url("../img/facebook-icon.png"); }

.fa-instagram:after {
  mask-image: url("../img/instagram-icon.png"); }

.fa-linkedin-in:after,
.fa-linkedin:after {
  mask-image: url("../img/linkedin-icon.png"); }

.fa-youtube:after {
  mask-image: url("../img/youtube-icon.png"); }

.fa-twitter:after {
  mask-image: url("../img/twitter-icon.png"); }

.fa-map-marker-alt:after {
  mask-image: url("../img/map-marker.svg"); }

.youtube-main {
  border: solid 0.5rem transparent;
  border-radius: 0.5rem;
  background-image: linear-gradient(#000000, #000000), linear-gradient(0deg, var(--primary-color) 0%, var(--primary-hover) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box; }
  @media (min-width: 768px) {
    .youtube-main {
      margin-top: 3rem; } }

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: .4s all;
  -moz-transition: .4s all;
  transition: .4s all; }
  .youtube-player img:hover {
    -webkit-filter: brightness(75%); }

.youtube-player .play {
  height: 56px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -28px;
  position: absolute;
  background: url("../img/youtube.png") no-repeat;
  background-size: contain;
  cursor: pointer;
  filter: grayscale(1);
  opacity: 0.9;
  transition: all 1s ease; }
  @media (min-width: 1200px) {
    .youtube-player .play {
      height: 70px;
      width: 100px;
      margin-left: -50x;
      margin-top: -35px; } }

.youtube-player:hover .play {
  filter: grayscale(0);
  opacity: 1; }

.entry-title {
  color: var(--secondary-color); }

.entry-content ul {
  list-style-type: none;
  line-height: 2rem;
  padding: 0 2rem 0 0; }
  .entry-content ul li {
    position: relative;
    margin-left: 2rem; }
    .entry-content ul li:before {
      content: "";
      background-color: var(--primary-color);
      mask-image: url("../img/green-tick.png");
      mask-repeat: no-repeat;
      mask-position: center;
      vertical-align: middle;
      min-width: 19px;
      height: 16px;
      position: absolute;
      top: 0.25rem;
      left: -2rem; }
    .entry-content ul li a {
      color: #3D4144; }
      .entry-content ul li a:hover {
        color: var(--primary-color);
        text-decoration: none; }

.container-left-half {
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  @media (min-width: 576px) {
    .container-left-half {
      max-width: calc(540px - 30px); } }
  @media (min-width: 768px) {
    .container-left-half {
      margin-right: 0;
      max-width: calc(360px - 30px); } }
  @media (min-width: 992px) {
    .container-left-half {
      max-width: calc(480px - 30px); } }
  @media (min-width: 1200px) {
    .container-left-half {
      max-width: calc(570px - 30px); } }

.book-form-main {
  background: linear-gradient(180deg, #21314a 90%, #E8E8E8 0%); }
  .book-form-main .bg-image {
    object-position: bottom; }
  .book-form-main .book-form-right {
    padding: 2rem; }
    @media (min-width: 996px) {
      .book-form-main .book-form-right {
        padding: 5rem 2rem 10rem 0; } }
    .book-form-main .book-form-right h4 {
      color: #FFFFFF;
      font-size: 2rem;
      font-weight: 700; }
      .book-form-main .book-form-right h4 span {
        color: var(--primary-color); }
    .book-form-main .book-form-right p {
      color: #fff;
      padding-top: 1.2rem; }
  .book-form-main .mauticform_wrapper {
    max-width: 100% !important;
    background: linear-gradient(180deg, #FAFAFA 38%, #F6F6F6 58%, #EEE 100%, #E9E9E9 100%);
    background-color: #f8f9fa;
    box-shadow: 0 0 10px -5px #626262;
    display: flex !important;
    padding: 1.5rem; }
    .book-form-main .mauticform_wrapper form {
      width: 100%; }
    .book-form-main .mauticform_wrapper .row {
      display: unset !important;
      margin: 0; }
    .book-form-main .mauticform_wrapper h3 {
      margin-bottom: 1.3rem;
      font-size: 1.5rem;
      font-weight: 700; }
      .book-form-main .mauticform_wrapper h3:after {
        display: block;
        content: " ";
        height: 0.25rem;
        width: 2rem;
        background-color: var(--primary-color);
        border-radius: 0.25rem;
        margin-top: 0.3rem; }
    .book-form-main .mauticform_wrapper h5 {
      margin-left: 0.25rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem; }
    .book-form-main .mauticform_wrapper .form-control {
      box-shadow: 0 0 0 #fff inset;
      border-radius: .3rem;
      color: #939393;
      font-size: 1rem; }
    .book-form-main .mauticform_wrapper .form-control:focus-visible {
      text-shadow: none;
      color: #2C2C2C; }
    .book-form-main .mauticform_wrapper .selecttime1 {
      color: #7C848B; }
    .book-form-main .mauticform_wrapper .btn {
      width: 100%;
      text-transform: unset;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-size: 1rem;
      font-weight: 800;
      color: #fff;
      background-color: var(--primary-hover);
      border: none;
      margin-left: -15px; }
      .book-form-main .mauticform_wrapper .btn:hover {
        background-color: var(--primary-color); }
    .book-form-main .mauticform_wrapper .in-touch {
      margin-top: 0.75rem; }
      @media (min-width: 768px) {
        .book-form-main .mauticform_wrapper .in-touch {
          margin-top: 0.25rem;
          line-height: 1.2; } }
    .book-form-main .mauticform_wrapper .minutes {
      color: var(--primary-hover); }
    .book-form-main .mauticform_wrapper textarea.form-control.is-valid, .book-form-main .mauticform_wrapper textarea.form-control.is-invalid {
      padding-right: calc(1.5em + .75rem);
      background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem); }

.page-template-book-now .book-form-main {
  background: unset; }
  @media (min-width: 992px) {
    .page-template-book-now .book-form-main .in-touch {
      margin-top: 0.75rem; } }

.page-contact .book-form-main {
  background: unset; }
  .page-contact .book-form-main h3:after {
    left: 15px; }
  .page-contact .book-form-main .btn {
    margin-left: 0; }

.field-hidden1 {
  display: none !important;
  visibility: hidden !important; }

.mauticform-errormsg {
  display: none; }

.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
  background-repeat: no-repeat;
  border-color: #FB3048;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

.is-valid {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnIHZpZXdCb3g9JzAgMCA4IDgnPjxwYXRoIGZpbGw9JyMyOGE3NDUnIGQ9J00yLjMgNi43M0wuNiA0LjUzYy0uNC0xLjA0LjQ2LTEuNCAxLjEtLjhsMS4xIDEuNCAzLjQtMy44Yy42LS42MyAxLjYtLjI3IDEuMi43bC00IDQuNmMtLjQzLjUtLjguNC0xLjEuMXonLz48L3N2Zz4=") !important;
  background-repeat: no-repeat;
  border-color: #28A745;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem); }

select.form-control {
  color: #6C757D;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.section-accordion {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  @media (min-width: 992px) {
    .section-accordion {
      margin-bottom: 5rem; } }
  .section-accordion .card {
    border: none;
    border-radius: 0;
    margin: 1.5rem 0;
    background-color: transparent;
    line-height: 1.6;
    border-bottom: 1px solid var(--tertiary-color) !important; }
    .section-accordion .card.active .card-header span:after {
      content: "-"; }
  .section-accordion ul {
    margin: 1rem 0 0; }
  .section-accordion .card-header {
    background-color: transparent;
    border-bottom: none;
    position: relative;
    padding-right: 2.25rem; }
    .section-accordion .card-header span:after {
      content: "+";
      position: absolute;
      top: 0.75rem;
      right: 1.125rem; }
    .section-accordion .card-header .accordion-link {
      color: var(--primary-hover);
      cursor: pointer;
      font-size: 1.25rem;
      font-weight: 600; }
      @media (min-width: 768px) {
        .section-accordion .card-header .accordion-link {
          font-size: 1.5rem; } }
  .section-accordion .card-body {
    padding-top: 0; }
  .section-accordion h2 {
    margin: 0 0 1.3rem 0 !important;
    font-size: 1.8rem;
    color: #000; }

.pagination {
  display: inline-flex;
  margin-top: 3rem; }
  .pagination .page-item .page-link {
    border-radius: 50px;
    margin: 5px;
    height: 37px;
    width: 38px; }
  .pagination .page-item a:hover {
    color: var(--tertiary-color);
    text-decoration: none; }

.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: var(--primary-color);
  border-color: var(--primary-color); }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--primary-color);
  background-color: #FFFFFF;
  border: 1px solid #DEE2E6; }

.pagination {
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

.above-points {
  background-position: center;
  background-repeat: no-repeat;
  color: #3D4144;
  text-align: center;
  background-size: cover;
  padding: 30px 0 0;
  position: relative;
  z-index: 2; }
  @media (min-width: 768px) {
    .above-points {
      padding: 45px; } }
  @media (min-width: 992px) {
    .above-points {
      padding: 4rem 0 0;
      background-size: cover; } }
  .above-points p {
    margin: 20px 0 0; }
  .above-points .mb-3 {
    font-size: 1.25rem; }
  @media (min-width: 768px) {
    .above-points .review-image {
      padding-top: 0; } }
  .above-points .col-md-8 {
    padding-top: 2rem; }
    @media (min-width: 768px) {
      .above-points .col-md-8 {
        padding-top: 3rem; } }
  .above-points .emp-img {
    left: 0;
    z-index: 1;
    bottom: -70px; }
    @media (min-width: 992px) {
      .above-points .emp-img {
        bottom: -49px; } }
    @media (min-width: 1600px) {
      .above-points .emp-img {
        bottom: -56px; } }
    @media (min-width: 2000px) {
      .above-points .emp-img {
        bottom: -75px; } }

.semi-circle-wrapper {
  height: 100px;
  width: 100%;
  overflow: hidden;
  background-color: #E8E8E8;
  position: relative;
  margin-top: -4rem; }
  @media (min-width: 992px) {
    .semi-circle-wrapper {
      margin-top: -2rem; } }
  .semi-circle-wrapper .semi-circle {
    position: absolute;
    background-color: #FFFFFF;
    height: 500vw;
    width: 500vw;
    border-radius: 50%;
    bottom: 0;
    left: -200vw;
    z-index: 1; }
    @media (min-width: 2000px) {
      .semi-circle-wrapper .semi-circle {
        height: 1000vw;
        width: 1000vw;
        left: -450vw; } }

.box-below {
  background-color: #E8E8E8;
  min-height: 1rem;
  height: auto;
  width: 100%; }

.page-mode-selling {
  padding-bottom: 2rem; }
  @media (min-width: 992px) {
    .page-mode-selling {
      padding-bottom: 4rem; } }
  .page-mode-selling .home-points {
    margin-bottom: -8rem; }
    @media (min-width: 768px) {
      .page-mode-selling .home-points {
        margin-bottom: -2rem; } }

.home-points {
  background: linear-gradient(#E8E8E8 0, #E8E8E8 65%, #FFFFFF 90%);
  padding-top: 6rem; }
  @media (min-width: 992px) {
    .home-points {
      background: linear-gradient(#E8E8E8 0, #E8E8E8 25%, #FFFFFF 60%); } }
  @media (max-width: 992px) {
    .home-points {
      margin-bottom: -6rem; } }
  @media (max-width: 768px) {
    .home-points {
      margin-bottom: -2rem; } }
  .home-points .points-wrapper {
    background-color: #FFFFFF;
    position: relative;
    text-align: center;
    padding: 2rem;
    margin-bottom: 6rem;
    border: 0.15rem solid #CDCDCD; }
    @media (min-width: 992px) {
      .home-points .points-wrapper {
        padding: 1rem;
        margin-bottom: 0;
        border: unset; } }
    @media (min-width: 1200px) {
      .home-points .points-wrapper {
        padding: 2rem; } }
    .home-points .points-wrapper h3 {
      font-size: 1.5rem; }
      @media (min-width: 1200px) {
        .home-points .points-wrapper h3 {
          padding: 0 1.25rem; } }
    .home-points .points-wrapper img {
      margin-top: -5rem;
      padding-bottom: 1rem; }
    .home-points .points-wrapper .btn {
      border-radius: 0.3rem; }

.choose-us {
  padding: 3rem 0 4rem;
  background: linear-gradient(90deg, var(--faq-color-1) 0%, var(--faq-color-3) 100%); }
  @media (min-width: 992px) {
    .choose-us {
      padding: 5rem;
      background: linear-gradient(90deg, var(--faq-color-1) 0%, var(--faq-color-2) 60%, var(--faq-color-3) 0%); } }
  @media (max-width: 575px) {
    .choose-us {
      margin-bottom: -4rem; } }
  .choose-us .container-left-half {
    color: #FFFFFF; }
    @media (min-width: 768px) and (max-width: 992px) {
      .choose-us .container-left-half {
        margin-right: auto;
        max-width: calc(768px - 90px); } }
    @media (min-width: 992px) {
      .choose-us .container-left-half {
        padding-right: 3rem; } }
    .choose-us .container-left-half h3 {
      font-size: 1.5rem; }
      @media (min-width: 1240px) {
        .choose-us .container-left-half h3 {
          font-size: 1.75rem; } }
  .choose-us .why-choose-image {
    height: auto; }
    @media (min-width: 992px) {
      .choose-us .why-choose-image {
        width: 450px;
        max-width: none; } }
    @media (min-width: 1200px) {
      .choose-us .why-choose-image {
        width: 540px; } }
  .choose-us .accordion {
    line-height: 1.5; }
  .choose-us .card {
    background-color: transparent;
    border: 0;
    font-size: 1rem;
    border-radius: 3px !important; }
    .choose-us .card div[aria-expanded] .alldown {
      border: solid #FFFFFF;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      float: right;
      margin-top: 0.5rem; }
      @media (min-width: 992px) {
        .choose-us .card div[aria-expanded] .alldown {
          border: solid white;
          border-width: 0 4px 4px 0; } }
    .choose-us .card div[aria-expanded="true"] .up {
      border: solid #FFFFFF;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      float: right;
      margin-top: 0.5rem; }
      @media (min-width: 992px) {
        .choose-us .card div[aria-expanded="true"] .up {
          border: solid white;
          border-width: 0 4px 4px 0; } }
    .choose-us .card div[aria-expanded="false"] .down {
      border: solid #FFFFFF;
      border-width: 0 4px 4px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      float: right;
      margin-top: 0.5rem; }
      @media (min-width: 992px) {
        .choose-us .card div[aria-expanded="false"] .down {
          border: solid white;
          border-width: 0 4px 4px 0; } }
    .choose-us .card .card-header {
      border: 0;
      border-bottom: 1px solid var(--secondary-hover);
      border-radius: 0;
      padding: 0.55rem 0.25rem 0.5rem 0;
      margin: 0.5rem 0;
      font-weight: 700;
      cursor: pointer;
      background-color: unset; }
      @media (min-width: 992px) {
        .choose-us .card .card-header {
          border-bottom: 0;
          border-radius: 3px !important;
          padding: 0.55rem 0.75rem;
          margin: 0.5rem 0;
          background-color: var(--primary-hover); } }
    .choose-us .card .card-body {
      padding: 0.5rem 0 1.5rem; }
      @media (min-width: 992px) {
        .choose-us .card .card-body {
          padding: 1rem 0; } }
      .choose-us .card .card-body a {
        color: #FFFFFF;
        text-decoration: underline; }
        .choose-us .card .card-body a:hover {
          color: #CCC; }

.custom-banner {
  padding: 1rem;
  margin-bottom: 5rem; }
  @media (min-width: 576px) {
    .custom-banner {
      padding: 5rem; } }
  @media (max-width: 576px) {
    .custom-banner {
      padding: 1rem;
      box-shadow: 0 0.05rem 0.4rem 0 rgba(0, 0, 0, 0.1); } }
  .custom-banner .call-banner-left {
    background-color: #FFFFFF;
    padding: 2rem 1rem;
    border-radius: 0.5rem; }
    @media (min-width: 768px) {
      .custom-banner .call-banner-left {
        padding: 3rem; } }
    .custom-banner .call-banner-left p {
      padding: 1rem 0; }
  .custom-banner .call-banner-right {
    padding: 0 0 4rem;
    text-align: center; }
    @media (min-width: 992px) {
      .custom-banner .call-banner-right {
        padding: 6rem 0 6rem 3rem; } }

.call-banner-mobile {
  width: 100%;
  max-width: none; }

.home-service {
  padding: 0 0 1.75rem; }
  @media (min-width: 768px) {
    .home-service {
      padding: 5.75rem 0; } }
  .home-service .services-listing {
    position: relative;
    padding-top: 1.5rem; }
  .home-service .bottom-image-service {
    height: auto;
    max-height: 200px;
    width: auto;
    margin: 0 auto -60px auto; }
    @media (min-width: 576px) {
      .home-service .bottom-image-service {
        max-height: 225px;
        position: absolute;
        bottom: 0;
        right: 0; } }
    @media (min-width: 768px) {
      .home-service .bottom-image-service {
        max-height: 260px; } }
  .home-service .home-service-main {
    text-align: center;
    transition: all 1s ease;
    padding-bottom: 18px;
    padding-top: 18px;
    position: relative; }
    @media (min-width: 768px) {
      .home-service .home-service-main {
        margin-top: 15px;
        margin-bottom: 15px; } }
    .home-service .home-service-main .service-image {
      width: auto;
      height: 90px;
      transition: all 1s ease; }
    .home-service .home-service-main h3, .home-service .home-service-main h4, .home-service .home-service-main h5 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
      color: #2C2C2C; }
    .home-service .home-service-main .desc-service {
      line-height: 1.7rem; }
    .home-service .home-service-main:hover .service-image {
      filter: grayscale(100%); }
    .home-service .home-service-main:hover a {
      text-decoration: none; }
    .home-service .home-service-main:hover h3, .home-service .home-service-main:hover h4, .home-service .home-service-main:hover h5 {
      color: var(--tertiary-color); }
  .home-service .card-title {
    color: #000; }
  .home-service .card-title-desc {
    padding: 2rem 0 0; }
  .home-service .location-services-icons h5 {
    margin-top: 1rem;
    color: #2E3192; }
  .home-service .btn-more i {
    font-size: 1.2rem;
    color: white;
    vertical-align: inherit;
    margin-top: -3px;
    margin-right: 0.5rem; }
  .home-service .btn-more span {
    font-size: 0.8rem;
    font-weight: 300;
    color: white;
    padding-left: 1rem; }
  .home-service .btn-more:hover span {
    color: #FFFFFF; }
  .home-service .disabled:hover {
    cursor: default; }

.location-services-icons {
  padding-top: 4rem;
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .location-services-icons {
      padding-top: 5rem;
      padding-bottom: 2rem; } }

.archive-services.home-service {
  padding: 0; }

.emergency {
  padding-top: 2rem;
  padding-bottom: 0; }
  @media (min-width: 992px) {
    .emergency {
      padding-top: 8rem;
      padding-bottom: 8rem; } }
  .emergency .main-box {
    background: linear-gradient(to bottom, #1E2D4A 0, #000 75%);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.2rem;
    color: #FFFFFF;
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      .emergency .main-box {
        margin-bottom: 0;
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    @media (min-width: 1200px) {
      .emergency .main-box {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
  .emergency .emergency-image {
    max-width: none;
    width: auto;
    z-index: 1; }
    @media (min-width: 992px) {
      .emergency .emergency-image {
        top: -50%;
        height: 200%;
        left: -25%; } }
    @media (min-width: 1200px) {
      .emergency .emergency-image {
        top: -58%;
        height: 215%;
        left: -26%; } }
  .emergency .info h3 {
    font-size: 1.3rem;
    color: #FFFFFF;
    font-weight: 700;
    white-space: nowrap;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .emergency .info h3 {
        font-size: 1.5rem; } }
    @media (min-width: 1200px) {
      .emergency .info h3 {
        font-size: 1.75rem; } }
  .emergency .info p {
    font-size: 1.3rem;
    color: var(--primary-hover);
    font-weight: 700;
    margin-bottom: 0;
    text-align: center; }
    @media (min-width: 992px) {
      .emergency .info p {
        font-size: 1.25rem; } }
    @media (min-width: 1200px) {
      .emergency .info p {
        font-size: 1.5rem; } }

.technology-section {
  margin-top: 3rem;
  margin-bottom: 0; }
  @media (min-width: 576px) {
    .technology-section {
      margin-top: 4rem;
      margin-bottom: 1rem; } }
  @media (min-width: 768px) {
    .technology-section {
      margin-top: 4rem;
      margin-bottom: 1rem; } }
  @media (min-width: 992px) {
    .technology-section {
      margin-top: 6rem;
      margin-bottom: 2rem; } }
  .technology-section .technology-image {
    max-width: none;
    height: auto;
    width: 100%; }
    @media (min-width: 768px) {
      .technology-section .technology-image {
        width: 85%; } }
    @media (max-width: 575px) {
      .technology-section .technology-image {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px); } }
  .technology-section .technology-title {
    margin-top: 2rem; }
    @media (min-width: 768px) {
      .technology-section .technology-title {
        margin-top: 0; } }
  .technology-section .technology-description {
    margin-top: 1.5rem;
    font-weight: 400;
    line-height: 1.7; }
    @media (min-width: 768px) {
      .technology-section .technology-description {
        margin-top: 2rem; } }

@media (min-width: 992px) {
  .three-points-inner .col-lg-4 .media-body {
    border-right: 1px solid #FFFFFF; }
  .three-points-inner .col-lg-4:last-child .media-body {
    border-right: none; } }

.three-points-inner .media {
  padding: 5% 0%; }
  @media (min-width: 768px) {
    .three-points-inner .media {
      padding: 4% 0%; } }
  @media (min-width: 992px) {
    .three-points-inner .media {
      padding: 13% 0%; } }
  .three-points-inner .media .icon img {
    width: 70%; }
    @media (min-width: 992px) {
      .three-points-inner .media .icon img {
        width: 100%; } }
  .three-points-inner .media .icon .fal {
    color: #FFFFFF;
    font-size: 30px;
    height: 30px;
    width: 30px;
    text-align: center; }
    @media (min-width: 992px) {
      .three-points-inner .media .icon .fal {
        width: 40px;
        height: 40px;
        font-size: 40px; } }
  .three-points-inner .media .media-body {
    padding: 0px 5px;
    white-space: nowrap; }
    @media (min-width: 992px) {
      .three-points-inner .media .media-body {
        padding: 15px 20px; } }
    .three-points-inner .media .media-body strong {
      color: #FFFFFF;
      font-size: 1rem; }
    .three-points-inner .media .media-body span {
      color: #FFF000;
      font-size: 1.5rem;
      font-weight: bold;
      font-style: italic; }

.awards-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .awards-section .image-center p {
    margin-bottom: 0; }
  .awards-section .image- img, .awards-section .image-left img, .awards-section .image-right img {
    height: 100%;
    padding-top: 0; }
    @media (min-width: 992px) {
      .awards-section .image- img, .awards-section .image-left img, .awards-section .image-right img {
        height: 90%;
        padding-top: 10%; } }
  @media (min-width: 768px) {
    .awards-section .image-left {
      text-align: right; } }
  @media (min-width: 768px) {
    .awards-section .image-right {
      text-align: left; } }

.location-near-me {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% center;
  padding-top: 15rem;
  padding-bottom: 2rem; }
  @media (min-width: 768px) {
    .location-near-me {
      background-position: center;
      padding-top: 4rem;
      padding-bottom: 4rem;
      margin-top: 5rem; } }
  @media (min-width: 996px) {
    .location-near-me {
      margin: 6rem 0; } }
  .location-near-me .location-near-me-right {
    border-radius: 0.5rem; }
    @media (min-width: 768px) {
      .location-near-me .location-near-me-right {
        margin: 5rem 0; } }
    .location-near-me .location-near-me-right .location-near-me-title {
      padding-top: 2rem;
      font-size: 2rem;
      font-weight: normal;
      color: #2C2C2C; }
      @media (min-width: 996px) {
        .location-near-me .location-near-me-right .location-near-me-title {
          font-size: 2.5rem;
          padding-top: 0; } }
    .location-near-me .location-near-me-right .location-near-me-sub_title {
      font-size: 2rem;
      font-weight: bold;
      color: var(--tertiary-hover); }
      @media (min-width: 996px) {
        .location-near-me .location-near-me-right .location-near-me-sub_title {
          font-size: 2.5rem; } }
    .location-near-me .location-near-me-right .location-near-me-bottom {
      color: #2C2C2C;
      padding-bottom: 2rem; }
      @media (min-width: 996px) {
        .location-near-me .location-near-me-right .location-near-me-bottom {
          padding-bottom: 0; } }
      .location-near-me .location-near-me-right .location-near-me-bottom a {
        color: var(--primary-color);
        text-decoration: none;
        font-weight: 600; }

.location-shortcode .asp_w {
  margin: 0 auto !important; }

@media (min-width: 576px) {
  .zip-section {
    margin-top: 3rem;
    margin-bottom: 3rem; } }

@media (min-width: 992px) {
  .zip-section {
    margin-top: 5rem;
    margin-bottom: 5rem; } }

@media (max-width: 768px) {
  .zip-section .container {
    padding: 0; }
    .zip-section .container .row {
      margin-left: 0;
      margin-right: 0; }
      .zip-section .container .row .col-12 {
        padding-left: 0;
        padding-right: 0; } }

@media (min-width: 992px) {
  .zip-section .first-col {
    padding-right: 0; } }

.zip-section .first-col img {
  width: 100%;
  height: auto;
  border-radius: 0; }
  @media (min-width: 576px) {
    .zip-section .first-col img {
      border-radius: 0 0 0.5rem 0.5rem; } }
  @media (min-width: 992px) {
    .zip-section .first-col img {
      border-radius: 0.5rem 0 0 0.5rem;
      height: 100%; } }

@media (min-width: 992px) {
  .zip-section .second-col {
    padding-left: 0; } }

.zip-section .second-col .zip-right {
  padding: 1.5rem 0.75rem;
  background-color: #21285E;
  border-radius: 0; }
  @media (min-width: 576px) {
    .zip-section .second-col .zip-right {
      border-radius: 0.5rem 0.5rem 0 0; } }
  @media (min-width: 992px) {
    .zip-section .second-col .zip-right {
      padding: 3.5rem 2rem;
      border-radius: 0 0.5rem 0.5rem 0; } }

.zip-section .second-col img {
  max-width: 50%; }
  @media (min-width: 992px) {
    .zip-section .second-col img {
      max-width: 75%; } }

.zip-section .second-col h4 {
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    .zip-section .second-col h4 {
      padding-bottom: 0.5rem; } }
  @media (min-width: 1200px) {
    .zip-section .second-col h4 {
      font-size: 2.15rem;
      padding-bottom: 1.25rem; } }

.zip-section .second-col p {
  font-size: 1rem;
  margin: 0;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .zip-section .second-col p {
      font-size: 1.33rem; } }

@media (max-width: 375px) {
  .trust-section .col-9 {
    padding: 0 1rem 0 0; } }

.trust-section .trust-sub-title {
  font-size: 1.25rem;
  font-weight: 600; }
  @media (min-width: 768px) {
    .trust-section .trust-sub-title {
      font-size: 1.75rem; } }

.trust-section h3 {
  font-weight: 400;
  font-style: italic;
  font-size: 1.75rem; }

.testimonial-section {
  padding-top: 3rem !important; }
  @media (min-width: 992px) {
    .testimonial-section {
      padding-top: 1.5rem !important; } }
  .testimonial-section .testimonials-subtitle {
    color: #3D4144;
    font-size: 1.2rem; }
    @media (min-width: 992px) {
      .testimonial-section .testimonials-subtitle {
        font-size: 1.75rem; } }
  .testimonial-section .testimonials-description {
    line-height: 1.5;
    margin: 2.5rem auto 2rem; }
    @media (min-width: 992px) {
      .testimonial-section .testimonials-description {
        margin: 2.5rem auto 4rem; } }
  .testimonial-section .card-text {
    background-color: #E8E8E8;
    padding: 2rem;
    border-radius: 0.5rem; }
  .testimonial-section .read_more {
    cursor: pointer; }
    .testimonial-section .read_more:hover {
      text-decoration: none !important; }
  .testimonial-section .read-more-dots {
    color: #000; }

.opening-hours {
  padding-top: 1rem;
  padding-bottom: 4rem; }
  .opening-hours .title {
    color: #2C2C2C;
    font-weight: 600; }
  .opening-hours .state {
    font-weight: 400;
    color: #8C8C8C; }
  .opening-hours .state-desc {
    font-size: 1rem; }
  .opening-hours .hours-section .title {
    font-size: 1rem;
    text-decoration: underline;
    color: #2C2C2C;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .opening-hours .hours-section .hours {
    color: var(--primary-hover);
    padding-left: 2rem; }
  .opening-hours .hours-male {
    width: 30%;
    bottom: -0.6rem;
    right: 0; }
    @media (min-width: 768px) {
      .opening-hours .hours-male {
        width: 40%; } }
    @media (min-width: 996px) {
      .opening-hours .hours-male {
        width: 41%; } }

.location-archive-section .location-archive-main {
  margin: 2rem 0; }
  .location-archive-section .location-archive-main a {
    text-decoration: none; }
  .location-archive-section .location-archive-main img {
    border-radius: 0.3rem;
    margin-bottom: 1.5rem; }
  .location-archive-section .location-archive-main h3 {
    color: #2C2C2C; }
  .location-archive-section .location-archive-main p {
    color: var(--primary-hover);
    text-decoration: none;
    font-weight: 600; }

.location-archive-section .employee-img-archive {
  bottom: -8rem;
  right: 1rem;
  width: 70%; }

.location-section {
  margin-bottom: 6rem;
  padding-bottom: 3rem; }
  @media (max-width: 576px) {
    .location-section {
      background-color: #F6F6F6; } }
  .location-section .text-wrapper {
    padding: 0.5rem 0 1rem; }
    @media (min-width: 768px) {
      .location-section .text-wrapper {
        padding: 3rem 0 1rem; } }
    @media (min-width: 992px) {
      .location-section .text-wrapper {
        padding: 6rem 0 0; } }
    @media (min-width: 1200px) {
      .location-section .text-wrapper {
        padding: 9rem 0 3rem; } }
    .location-section .text-wrapper .text-title {
      font-size: 1.75rem;
      font-weight: bolder;
      color: #2C2C2C;
      margin-bottom: 1rem; }
      @media (min-width: 1200px) {
        .location-section .text-wrapper .text-title {
          font-size: 2rem; } }
    .location-section .text-wrapper .text-subtitle {
      font-weight: bold;
      color: var(--secondary-color);
      margin-top: 2rem; }
      @media (min-width: 1200px) {
        .location-section .text-wrapper .text-subtitle {
          font-size: 1.5rem; } }
    .location-section .text-wrapper .text-description a {
      color: var(--primary-hover); }
    .location-section .text-wrapper .ajaxsearchpro {
      margin: 0 !important; }
  .location-section .oz-map {
    position: relative;
    width: 100%;
    height: auto; }
  .location-section #ajaxsearchpro1_1 {
    width: 80%; }
    @media (min-width: 768px) {
      .location-section #ajaxsearchpro1_1 {
        width: 60%; } }

.service-banner {
  background-color: #E8E8E8; }
  .service-banner .service-text {
    margin: 3rem 0; }
    @media (min-width: 992px) {
      .service-banner .service-text {
        margin: 5rem 0; } }
  .service-banner .service-title {
    font-size: 2rem; }
  .service-banner p {
    margin: 1rem 0 1.5rem;
    line-height: 1.6; }

.festival-section-mobile {
  width: 100%;
  max-width: none; }

.festival-section {
  margin-bottom: 0; }
  .festival-section .left-section {
    background-color: #10142C;
    padding: 3rem 1rem; }
    @media (min-width: 992px) {
      .festival-section .left-section {
        padding: 4rem 1rem; } }
    .festival-section .left-section h3 {
      color: #FFFFFF; }
      @media (min-width: 768px) {
        .festival-section .left-section h3 {
          font-size: 2.25rem;
          font-weight: 700; } }
    .festival-section .left-section p {
      color: #FFFFFF;
      padding: 1rem 0; }
      @media (min-width: 768px) {
        .festival-section .left-section p {
          padding: 1rem 2rem 1rem 0; } }
    .festival-section .left-section .btn {
      padding: .75rem 2.5rem;
      font-size: 1rem; }

.blog-callout {
  padding: 0 2rem 2.5rem;
  margin: 3rem auto;
  border: 1px solid var(--primary-color);
  border-left-width: 0.25rem;
  border-radius: 0.25rem; }
  .blog-callout .btn {
    margin-top: 0.25rem;
    border: 2px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.75rem 2.75rem;
    vertical-align: middle; }
    .blog-callout .btn:active, .blog-callout .btn:focus, .blog-callout .btn:hover {
      background-color: var(--secondary-hover) !important;
      box-shadow: none !important;
      border-color: var(--secondary-hover) !important;
      color: #fff; }

.blog-form h2 {
  color: var(--primary-color) !important;
  font-size: 2rem;
  margin: 1.5rem 0;
  font-weight: 800; }

.blog-form h3 {
  font-size: 1.25rem;
  margin: 1rem 0;
  font-weight: 600; }

.blog-form h2, .blog-form h3 {
  text-align: left; }

.blog-form p {
  color: #3D4144; }

.blog-form .btn {
  margin: 1rem 0;
  text-transform: uppercase;
  background-color: var(--tertiary-color); }
  .blog-form .btn:hover {
    background-color: var(--tertiary-hover); }

.blog-form .minutes {
  color: var(--primary-color); }

.introduction-section {
  background-color: #E8E8E8;
  padding: 3rem 0 0 0; }
  @media (min-width: 992px) {
    .introduction-section {
      padding: 4rem 0 3rem 0; } }
  .introduction-section .left-side-intro .img-border {
    border: 0.4rem solid #FFFFFF;
    border-radius: 0.4rem; }
    .introduction-section .left-side-intro .img-border .left-side-img {
      border-radius: 0.4rem; }
  .introduction-section .right-side-intro {
    padding-left: 1rem;
    padding-bottom: 1rem; }
    @media (min-width: 992px) {
      .introduction-section .right-side-intro {
        padding-left: 2rem;
        padding-bottom: 0; } }
    .introduction-section .right-side-intro h2 {
      color: #2C2C2C;
      font-size: 2.2rem;
      font-weight: bold;
      padding-top: 2rem; }
      @media (min-width: 768px) {
        .introduction-section .right-side-intro h2 {
          padding-top: 0;
          font-size: 2.1rem; } }
      @media (min-width: 992px) {
        .introduction-section .right-side-intro h2 {
          font-size: 3rem;
          padding-top: 0; } }
      .introduction-section .right-side-intro h2 span {
        color: var(--primary-hover); }
    .introduction-section .right-side-intro p {
      padding-top: 1rem;
      font-weight: 600; }
    .introduction-section .right-side-intro img {
      margin-top: 1rem;
      margin-bottom: 1.5rem; }
      @media (min-width: 992px) {
        .introduction-section .right-side-intro img {
          margin-bottom: 0; } }
    .introduction-section .right-side-intro ul {
      color: #FFF;
      padding-left: 0;
      list-style: none;
      padding-top: 1rem; }
      .introduction-section .right-side-intro ul li {
        position: relative;
        color: #2C2C2C;
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        display: block;
        padding-left: 2rem; }
        @media (min-width: 768px) {
          .introduction-section .right-side-intro ul li {
            font-size: 1rem; } }
        @media (min-width: 996px) {
          .introduction-section .right-side-intro ul li {
            font-size: 1.2rem; } }
        .introduction-section .right-side-intro ul li:before {
          position: absolute;
          content: "";
          mask-image: url("../img/shield-bullet.png");
          mask-repeat: no-repeat;
          mask-position: top left;
          height: 1.5rem;
          width: 1.5rem;
          left: 0;
          top: 15%;
          background-color: var(--primary-color); }
  .introduction-section .btn {
    width: 100%;
    margin: 15px 0px 20px; }
    @media (min-width: 768px) {
      .introduction-section .btn {
        width: 50%; } }

@media (min-width: 768px) {
  .single-brands-intro .introduction-section {
    padding: 3rem 0 2rem 0; } }

@media (min-width: 992px) {
  .single-brands-intro .introduction-section {
    padding: 3rem 0; } }

@media (min-width: 992px) {
  .single-brands-intro .introduction-section .right-side-intro {
    min-height: 16rem; } }

@media (min-width: 768px) {
  .single-brands-intro .btn {
    margin-top: 2rem !important; } }

.employee-main .employee-img {
  position: absolute;
  z-index: 1;
  right: 0.2%;
  bottom: -0.2rem;
  height: 18rem; }
  @media (min-width: 992px) {
    .employee-main .employee-img {
      right: -2%;
      bottom: -54px;
      height: auto; } }

.semi-circle-wrapper-intro {
  height: 100px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  position: relative; }
  @media (min-width: 992px) {
    .semi-circle-wrapper-intro {
      margin-top: -3rem;
      margin-bottom: 3rem; } }
  .semi-circle-wrapper-intro .semi-circle-intro {
    position: absolute;
    background-color: #E8E8E8;
    height: 500vw;
    width: 500vw;
    border-radius: 50%;
    bottom: 0;
    left: -200vw; }
    @media (min-width: 2000px) {
      .semi-circle-wrapper-intro .semi-circle-intro {
        height: 1000vw;
        width: 1000vw;
        left: -450vw; } }

.bottom-space {
  margin-bottom: 5rem; }
  @media (min-width: 992px) {
    .bottom-space {
      margin-bottom: 8rem; } }

.content-banner {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center; }
  @media (min-width: 768px) {
    .content-banner {
      margin-top: 3rem;
      margin-bottom: 3rem; } }
  .content-banner img {
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 1200px) {
      .content-banner img {
        max-width: 1110px; } }

.location-main .entry-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--secondary-color); }
  @media (min-width: 768px) {
    .location-main .entry-title {
      font-size: 40px; } }

.location-main .youtube-wrapper {
  width: 100%;
  padding-bottom: 54.25%;
  padding-top: 15px;
  margin-bottom: 1rem; }
  @media (min-width: 768px) {
    .location-main .youtube-wrapper {
      float: left;
      width: 320px;
      margin-right: 15px;
      padding-bottom: 28.25%;
      padding-top: 0;
      margin-bottom: 0; } }
  @media (min-width: 968px) {
    .location-main .youtube-wrapper {
      width: 450px;
      margin-right: 30px; } }

.location-main .entry-content .social {
  margin-top: 35px; }
  .location-main .entry-content .social .fa {
    background-color: var(--secondary-color);
    border-radius: 25px;
    color: #FFF;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    margin: auto 3px;
    width: 25px;
    font-size: 10px;
    text-align: center; }
  .location-main .entry-content .social .fa-twitter {
    background-color: #329FF5; }
  .location-main .entry-content .social .fa-facebook {
    background-color: #3B5A96; }
  .location-main .entry-content .social .fa-instagram {
    background-color: #0078B8; }
  .location-main .entry-content .social .fa-share-alt {
    background-color: #FFFFFF;
    color: #2C2C2C; }
  .location-main .entry-content .social .related-suburb {
    color: #3D4144;
    text-decoration: underline;
    font-size: 14px;
    padding-right: 6px; }

.location-main .entry-content .google-map-main {
  padding-top: 35px; }

.location-main .location-services {
  background-color: #F8F8F8; }

.map-container {
  overflow: hidden;
  height: 100%; }

.map-container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }
  @media (min-width: 768px) {
    .map-container iframe {
      overflow: hidden;
      border-radius: 0 15px 15px 0; } }

.main-map {
  top: 32%; }
  .main-map h1 {
    font-size: 3.75rem;
    font-weight: 700;
    color: #FFFFFF;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }

.masthead {
  height: 9rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .masthead {
      height: 25rem; } }

.location-title {
  font-size: 2rem;
  font-weight: 700 !important;
  color: #FFFFFF;
  padding: 0;
  display: inline-block; }
  @media (min-width: 576px) {
    .location-title {
      font-size: 2.75rem; } }
  @media (min-width: 768px) {
    .location-title {
      font-size: 4rem; } }
  @media (min-width: 992px) {
    .location-title {
      font-size: 4.5rem; } }

.location-subtitle {
  font-size: 1rem;
  font-weight: 400;
  color: #FFFFFF;
  display: block; }
  @media (min-width: 576px) {
    .location-subtitle {
      font-size: 1.5rem; } }
  @media (min-width: 768px) {
    .location-subtitle {
      font-size: 2rem;
      font-weight: 600; } }

.hm-serv-desc {
  display: none; }

@media (max-width: 576px) {
  .intro-sell li {
    display: flex; } }

@media (max-width: 576px) {
  .intro-sell .fa-shield-check {
    font-size: 2.15rem !important; } }

@media (min-width: 992px) {
  .near-city {
    margin-bottom: 7rem; } }

.near-city h4 {
  color: var(--secondary-color) !important;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 1.4rem 0 1.3rem 0; }

.near-city p {
  color: #3D4144; }

.near-city img {
  opacity: 0.1; }

.near-city .card-img-overlay {
  top: 59%;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 0; }
  @media (min-width: 992px) {
    .near-city .card-img-overlay {
      top: 62%;
      left: 48%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 1rem;
      font-weight: 700; } }

.archive-brand .left-side-brand img {
  left: 0;
  max-width: 200%;
  width: auto; }

.archive-brand .brand-alphabets {
  margin: 1rem 0 4rem; }
  .archive-brand .brand-alphabets .col-12 {
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .archive-brand .brand-alphabets h5 {
    display: inline-block;
    margin: 0 30px 0 0;
    font-size: 1rem; }
  .archive-brand .brand-alphabets ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0; }
    .archive-brand .brand-alphabets ul li {
      display: inline-block;
      margin: 0 10px; }
      @media (min-width: 1200px) {
        .archive-brand .brand-alphabets ul li {
          margin: 0 13px; } }
      .archive-brand .brand-alphabets ul li a {
        color: #3D4144;
        font-size: 1rem;
        font-weight: 600; }
        .archive-brand .brand-alphabets ul li a:hover {
          color: var(--primary-color); }
      .archive-brand .brand-alphabets ul li:first-child {
        margin-left: 0; }
      .archive-brand .brand-alphabets ul li:last-child {
        margin-right: 0; }
  .archive-brand .brand-alphabets #dropdownAlphabetsLink {
    cursor: pointer; }
  .archive-brand .brand-alphabets .dropdown-menu {
    height: auto;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: 5rem;
    margin: -1rem 0 0 10rem; }

@media (min-width: 1200px) {
  .archive-brand .brands-loop {
    margin-top: 2rem; } }

.archive-brand .brands-loop .row {
  margin-top: 0;
  margin-bottom: 0; }
  .archive-brand .brands-loop .row h3 {
    font-weight: 700;
    line-height: 0.85; }
  .archive-brand .brands-loop .row p {
    color: #3D4144; }
  .archive-brand .brands-loop .row a {
    font-weight: 600;
    font-size: 1rem; }
  .archive-brand .brands-loop .row .btn-brands {
    padding: 0;
    border: none !important;
    background-color: #FFFFFF;
    color: #555;
    border-radius: 0; }
    .archive-brand .brands-loop .row .btn-brands:hover, .archive-brand .brands-loop .row .btn-brands:active {
      color: #297CB7 !important;
      box-shadow: none;
      padding: 0;
      background-color: #fff !important; }

.archive-brand .brands-anchor {
  display: block;
  position: relative;
  visibility: hidden;
  top: -145px; }

.blog-content {
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .blog-content img {
    border-radius: 0.5rem; }
  .blog-content .card-img-overlay {
    bottom: 1rem; }
  .blog-content .card {
    margin: 2rem 0;
    border-radius: 1rem;
    box-shadow: 0 0 15px -5px #3D4144;
    border: none;
    height: 33rem; }
    .blog-content .card .card-img-top {
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      position: relative; }
    .blog-content .card .attachment-blogSmall {
      border-radius: 1rem 1rem 0 0; }
  .blog-content .blog-date {
    position: absolute;
    padding: 0.25rem 0.5rem;
    background-color: var(--primary-color);
    color: #FFFFFF;
    font-weight: 600;
    border-radius: 0.33rem;
    top: 20px;
    right: 15px; }
  .blog-content .entry-header {
    border-bottom: 1px solid #E8E8E8;
    padding-bottom: 1rem;
    color: #2C2C2C; }
    .blog-content .entry-header .entry-title a {
      color: #2C2C2C;
      font-size: 1.5rem;
      font-weight: 600; }
  .blog-content .entry-content {
    padding-top: 0.75rem; }
    .blog-content .entry-content p {
      margin: 0;
      color: #3D4144;
      font-size: 1.12rem;
      line-height: 1.6; }
    .blog-content .entry-content .blog-readmore {
      font-weight: 600;
      margin: 2rem 0 1rem;
      display: inline-block; }
      .blog-content .entry-content .blog-readmore i {
        font-size: 0.75rem; }

.blog-right {
  padding-bottom: 2rem; }
  .blog-right img {
    border-radius: 15px;
    width: 100%; }
  .blog-right .title {
    color: #2C2C2C !important;
    font-size: 2.5rem !important;
    font-weight: 700 !important;
    margin: 2rem 0 1.3rem 0 !important; }
  .blog-right h2, .blog-right h3, .blog-right strong {
    color: #2C2C2C !important;
    font-weight: 700 !important; }
  .blog-right h2, .blog-right h3 {
    font-size: 2rem !important;
    margin: 2rem 0 1.3rem 0 !important; }
  .blog-right .description h4, .blog-right .description h5 {
    color: #2C2C2C; }
  .blog-right .description ul {
    list-style-type: none;
    line-height: 2rem;
    padding: 0; }
    .blog-right .description ul li {
      display: flex;
      position: relative; }
      .blog-right .description ul li:before {
        content: "";
        background-color: transparent;
        background-image: url("../img/green-tick.png");
        display: table;
        background-repeat: no-repeat;
        background-position: center;
        vertical-align: middle;
        min-width: 19px;
        height: 16px;
        margin-right: 0.8rem;
        margin-top: 0.3rem; }
      .blog-right .description ul li a {
        color: #3D4144; }
        .blog-right .description ul li a:hover {
          color: var(--primary-hover); }

.social-blog h6 {
  padding-bottom: 1rem; }

.social-blog .fab {
  padding-right: 0.5rem; }

.social-blog a:hover {
  text-decoration: none; }

.social-blog img {
  display: inline;
  height: 15px;
  width: auto;
  margin: 0 5px;
  border-radius: 0; }

.page-contact {
  padding-top: 2rem; }
  @media (min-width: 768px) {
    .page-contact {
      padding-bottom: 0; } }
  @media (min-width: 1200px) {
    .page-contact .contact-left {
      padding-right: 4rem; } }
  .page-contact .contact-right {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 4rem; }
    @media (min-width: 768px) {
      .page-contact .contact-right {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0; } }
  @media (min-width: 768px) {
    .page-contact {
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .page-contact .contact-left .mauticform_wrapper {
    padding: 2.4rem; }
  .page-contact .cont-call {
    padding-top: 2rem;
    font-size: 2rem;
    color: #626262; }
    @media (min-width: 992px) {
      .page-contact .cont-call {
        font-size: 3.5rem; } }
    .page-contact .cont-call a {
      color: var(--primary-hover);
      font-weight: 700; }
  .page-contact p {
    color: #626262;
    line-height: 2rem; }
  .page-contact .expert {
    font-size: 1rem;
    color: #2C2C2C;
    padding-bottom: 1rem; }
    .page-contact .expert span {
      color: var(--primary-color);
      font-weight: bold; }
  .page-contact .contact-vanMobile {
    margin: 0 auto -40px auto;
    z-index: 2;
    max-width: 70%; }
    @media (min-width: 992px) {
      .page-contact .contact-vanMobile {
        max-width: 100%; } }
  @media (min-width: 768px) {
    .page-contact .contact-social {
      padding-bottom: 3rem; } }
  .page-contact .contact-social .fab {
    font-size: 2rem;
    padding-right: 10px;
    color: var(--primary-color); }
  .page-contact .contact-social ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .page-contact .contact-social ul li {
      display: inline-block; }

.page-contact-selling .home-points {
  margin-bottom: -6rem; }
  @media (min-width: 968px) {
    .page-contact-selling .home-points {
      margin-bottom: -2rem; } }

.page-contact-selling .page-mode-selling {
  padding-bottom: 0; }

.page-contact-award {
  padding-top: 0; }

.thankyou_wrapper {
  background-color: #E8E8E8;
  background-image: linear-gradient(180deg, #FAFAFA 38%, #F6F6F6 58%, #EEE 100%, #E9E9E9 100%);
  border-radius: 0.625rem;
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 0.625rem -0.25rem #626262;
  margin-bottom: 1rem; }
  .thankyou_wrapper .thankyou_inner {
    border: 0.25rem solid #FFF;
    border-radius: 0.625rem;
    padding: 3rem 2rem; }
  .thankyou_wrapper h1 {
    color: var(--primary-hover);
    font-size: 2rem;
    margin: 0.5rem auto; }
  .thankyou_wrapper p {
    color: #3D4144;
    margin-top: 1rem;
    margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .thankyou_wrapper h1 {
      font-size: 3.5rem; } }

.about-intro-image {
  bottom: -20px !important;
  width: 100% !important; }

.faq-intro-image {
  min-width: 0 !important;
  left: 10% !important; }
  @media (min-width: 992px) {
    .faq-intro-image {
      left: 20% !important; } }
  @media (min-width: 1200px) {
    .faq-intro-image {
      left: 25% !important; } }

.state-main .state-intro {
  border-radius: 15px;
  background-color: #F3F3F3; }
  .state-main .state-intro .bg-image {
    border-radius: 15px 0 0 15px;
    background-position: center; }

.suburb-char {
  margin: 2rem 0 .5rem .25rem;
  font-weight: 700; }

.suburb-name {
  color: #626262;
  margin: .15rem .25rem;
  padding: 0;
  display: inline-block; }

.near-me {
  padding: 3rem 1rem;
  margin-bottom: 3rem; }
  @media (min-width: 576px) {
    .near-me {
      padding: 5rem; } }
  .near-me .location-title {
    font-size: 2rem;
    font-weight: 400 !important;
    color: #FFFFFF; }
    @media (min-width: 768px) {
      .near-me .location-title {
        font-size: 1.5rem;
        font-weight: unset !important; } }
    @media (min-width: 992px) {
      .near-me .location-title {
        font-size: 2rem; } }
  .near-me .location-sub-title {
    font-weight: 700 !important;
    color: #FFFFFF;
    font-size: 2rem; }
    @media (min-width: 768px) {
      .near-me .location-sub-title {
        font-size: 2.5rem; } }
    @media (min-width: 992px) {
      .near-me .location-sub-title {
        font-size: 4rem; } }
  .near-me .location-shortcode .asp_non_compact {
    min-width: 225px !important; }
  .near-me .location-shortcode .wpdreams_asl_container {
    padding-top: 10px;
    padding-bottom: 10px; }
  .near-me .location-shortcode .asl_w * {
    padding-right: 10px; }
  .near-me .location-shortcode #ajaxsearchlite1 .probox .proinput input {
    font-size: 15px; }
  .near-me .location-text-desc {
    font-size: 1rem;
    color: #FFFFFF;
    padding-top: 0.5rem; }
    @media (min-width: 992px) {
      .near-me .location-text-desc {
        padding-top: 1rem; } }

.comp-section {
  margin: 3rem 0; }
  .comp-section .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 1rem;
    vertical-align: top;
    font-weight: 400;
    margin-left: 3px;
    color: #FFFFFF;
    border-radius: 0.25rem; }
    .comp-section .badge-danger {
      background-color: #dc3545; }
    .comp-section .badge-success {
      background-color: #28a745; }
    .comp-section .badge-primary {
      background-color: #007bff; }

.single-comp {
  margin: 0 0 2.625rem 0;
  padding: 0 0 2.625rem 0;
  border-bottom: 2px dotted #CCC; }
  .single-comp:last-of-type {
    margin: 0;
    padding: 0;
    border-bottom: none; }
  .single-comp .comp-main {
    margin-top: 3.5rem;
    margin-bottom: 1rem;
    position: relative; }
  .single-comp h1 {
    font-weight: 700;
    color: #2C2C2C;
    font-size: 2.5rem; }
    @media (min-width: 992px) {
      .single-comp h1 {
        font-size: 3rem; } }
  .single-comp h4 {
    color: var(--secondary-hover);
    font-size: 1.75rem;
    font-weight: 500;
    margin: 1.4rem 0 1.3rem 0; }
  .single-comp .download-lists a {
    display: flex;
    position: relative; }
    .single-comp .download-lists a:before {
      content: "";
      background-color: transparent;
      background-image: url("../img/pdf17.svg");
      display: table;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      vertical-align: middle;
      width: 1.5rem;
      height: 1.25rem;
      margin-right: 0.8rem;
      margin-bottom: 0.8rem; }
  .single-comp .comp-enter {
    margin: 1.5rem 0 4rem 0; }
  .single-comp .comp-enter-fb {
    background-color: #3B5998;
    color: #FFFFFF;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0;
    border-radius: 0.3rem;
    text-decoration: none; }
    .single-comp .comp-enter-fb:hover, .single-comp .comp-enter-fb:active, .single-comp .comp-enter-fb:focus {
      background-color: #2B406E;
      color: #FFFFFF; }

.comp-date {
  margin: -5px 0 20px 0; }

.comp-date-title {
  font-weight: 400;
  color: #3D4144;
  width: 90px;
  display: inline-block; }

.btn-comps {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  font-size: 1rem;
  font-weight: 400;
  padding: .5rem 1.25rem;
  margin: .5rem 0 0 0; }
