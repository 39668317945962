.comp-section {
	margin: 3rem 0;
	
	.badge {
		display:        inline-block;
		padding:        .25em .4em;
		font-size:      1rem;
		vertical-align: top;
		font-weight:    400;
		margin-left:    3px;
		color:          $white-color;
		border-radius:  0.25rem;
		
		&-danger {
			background-color: $red;
		}
		
		&-success {
			background-color: $green;
		}
		
		&-primary {
			background-color: $blue;
		}
	}
}

.single-comp {
	margin:        0 0 2.625rem 0;
	padding:       0 0 2.625rem 0;
	border-bottom: 2px dotted #CCC;
	
	&:last-of-type {
		margin:        0;
		padding:       0;
		border-bottom: none;
	}
	
	.comp-main {
		margin-top:    3.5rem;
		margin-bottom: 1rem;
		position:      relative;
	}
	
	h1 {
		font-weight: 700;
		color:       $black-color;
		font-size:   2.5rem;
		@media(min-width: 992px) {
			font-size: 3rem;
		}
	}
	
	h4 {
		color:       $secondary-hover;
		font-size:   1.75rem;
		font-weight: 500;
		margin:      1.4rem 0 1.3rem 0;
	}
	
	.download-lists {
		a {
			display:  flex;
			position: relative;
			
			&:before {
				content:             "";
				background-color:    transparent;
				background-image:    url("#{$img-path}/pdf17.svg");
				display:             table;
				background-repeat:   no-repeat;
				background-position: center;
				background-size:     contain;
				vertical-align:      middle;
				width:               1.5rem;
				height:              1.25rem;
				margin-right:        0.8rem;
				margin-bottom:       0.8rem;
			}
		}
	}
	
	.comp-enter {
		margin: 1.5rem 0 4rem 0;
	}
	
	.comp-enter-fb {
		background-color: $facebook-color;
		color:            $white-color;
		padding:          0.5rem 1.5rem;
		margin:           1rem 0;
		border-radius:    0.3rem;
		text-decoration:  none;
		
		&:hover, &:active, &:focus {
			background-color: #2B406E;
			color:            $white-color;
		}
	}
}

.comp-date {
	margin: -5px 0 20px 0;
}

.comp-date-title {
	font-weight: 400;
	color:       $dark-color;
	width:       90px;
	display:     inline-block;
}

.btn-comps {
	border:           2px solid $primary-color;
	background-color: $primary-color;
	font-size:        1rem;
	font-weight:      400;
	padding:          .5rem 1.25rem;
	margin:           .5rem 0 0 0
}