.home-service {
	padding: 0 0 1.75rem;
	@media (min-width: 768px) {
		padding: 5.75rem 0;
	}
	
	.services-listing {
		position:    relative;
		padding-top: 1.5rem;
	}
	
	.bottom-image-service {
		height:     auto;
		max-height: 200px;
		width:      auto;
		margin:     0 auto -60px auto;
		@media (min-width: 576px) {
			max-height: 225px;
			position:   absolute;
			bottom:     0;
			right:      0;
		}
		@media (min-width: 768px) {
			max-height: 260px;
		}
	}
	
	.home-service-main {
		text-align:     center;
		transition:     all 1s ease;
		padding-bottom: 18px;
		padding-top:    18px;
		position:       relative;
		@media (min-width: 768px) {
			margin-top:    15px;
			margin-bottom: 15px;
		}
		
		.service-image {
			width:      auto;
			height:     90px;
			transition: all 1s ease;
		}
		
		h3, h4, h5 {
			font-size:     1.25rem;
			font-weight:   600;
			margin-top:    1.5rem;
			margin-bottom: 0.75rem;
			color:         $black-color;
		}
		
		.desc-service {
			line-height: 1.7rem;
		}
		
		&:hover {
			.service-image {
				filter: grayscale(100%);
			}
			
			a {
				text-decoration: none;
			}
			
			h3, h4, h5 {
				color: $tertiary-color;
			}
		}
	}

	.card-title{ color: $black}
	.card-title-desc {
		padding: 2rem 0 0;
	}
	
	.location-services-icons {
		h5 {
			margin-top: 1rem;
			color:      #2E3192;
		}
	}
	
	.btn-more {
		i {
			font-size:      1.2rem;
			color:          white;
			vertical-align: inherit;
			margin-top:     -3px;
			margin-right:   0.5rem;
		}
		
		span {
			font-size:    0.8rem;
			font-weight:  300;
			color:        white;
			padding-left: 1rem;
		}
		
		&:hover {
			span {
				color: $white-color;
			}
		}
	}
	
	.disabled {
		&:hover {
			cursor: default;
		}
	}
}

.location-services-icons {
	padding-top:    4rem;
	padding-bottom: 0;
	@media (min-width: 992px) {
		padding-top:    5rem;
		padding-bottom: 2rem;
	}
}

.archive-services.home-service {
	padding: 0;
}