/*Sidepanel CSS*/
.screen-overlay {
	height:           100%;
	z-index:          30;
	position:         fixed;
	top:              0;
	right:            0;
	opacity:          0;
	visibility:       hidden;
	background-color: rgba(34, 34, 34, 0.6);
	transition:       opacity .2s linear, visibility .1s, width 1s ease-in;
	
	&.show {
		transition: opacity .5s ease, width 0s;
		opacity:    1;
		width:      100%;
		visibility: visible;
	}
}

.offcanvas {
	min-width:        320px;
	width:            90%;
	visibility:       hidden;
	transform:        translateX(100%);
	transition:       all .2s;
	border-radius:    0;
	box-shadow:       0 5px 10px rgba(0, 0, 0, .2);
	display:          block;
	position:         fixed;
	top:              0;
	right:            0;
	height:           100%;
	z-index:          1200;
	overflow-y:       auto;
	overflow-x:       hidden;
	background-color: #26395A;
	@media(min-width: 576px) {
		width: 420px;
	}
	
	&.show {
		visibility: visible;
		transform:  translateX(0);
		transition: transform .2s;
	}
}

.btn-close {
	display:    block;
	position:   fixed;
	z-index:    33;
	right:      2rem;
	top:        2rem;
	cursor:     pointer;
	transition: all 1s ease;
	
	&:hover {
		transform: scale(1.1);
	}
	
	.bar {
		width:            35px;
		height:           3px;
		border-radius:    3px;
		margin:           11px auto;
		background-color: $white-color;
		
		&:first-child {
			transform: translateY(7px) rotate(45deg);
		}
		
		&:last-child {
			transform: translateY(-7px) rotate(-45deg);
		}
	}
}

#mobile-menu {
	line-height: 1;
	
	.menu-item {
		//padding: 0 1rem;
		a {
			color:     $white-color;
			font-size: 1.5rem;
			padding:   0.75rem 1rem;
		}
	}
	
	li {
		ul {
			list-style:    none;
			background:    transparent;
			color:         white;
			position:      relative !important;
			transform:     none !important;
			padding:       0;
			border-radius: unset;
			border:        none;
			
			li {
				//padding: .25rem 0;
				display: block;
				
				a {
					font-size: 1rem !important;
					color:     $white !important;
					padding:   0.5rem 1rem !important;
					display:   inline-block;
					
					&:hover, &:focus {
						background-color: transparent !important;
					}
				}
				
				ul {
					li {
						//padding: .25rem 0 !important;
						a {
							margin-left: 1.5rem !important;
							padding:     0.25rem 0;
							font-size:   1rem !important;
						}
					}
				}
			}
		}
		
		.dropdown-toggle {
			&:after {
				content:      "";
				border-left:  5px solid transparent;
				border-right: 5px solid transparent;
				border-top:   5px solid $white-color;
			}
		}
	}
	
	.dropdown-menu {
		padding:    0.5rem 0 0.25rem 1rem !important;
		margin-top: -0.5rem;
		float:      none;
		
		li {
			&.dropdown a:before {
				content:      "+";
				margin-right: 5px;
				color:        $white-color;
				font-weight:  600;
				font-size:    1.2rem;
			}
		}
		
		.dropdown-menu {
			padding:    0 0 0.25rem !important;
			margin-top: 0;
			float:      unset;
			
			li {
				width: 100%;
				
				a:before {
					content: "";
				}
			}
		}
	}
	
	.menu-item-has-children {
		position: relative;
	}
	
	.menu-item-has-children a::after {
		transition:     all 0.25s;
		vertical-align: 2px;
	}
	
	.menu-item-has-children a[aria-expanded="true"]::after {
		transform:      rotate(90deg);
		vertical-align: -2px;
	}
	
	.menu-item-has-children .sub-menu {
		top:          0;
		left:         100%;
		margin-left:  .1rem;
		margin-right: .1rem;
	}
}